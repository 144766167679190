import RoomService from '@/services/room.service';

export const createReservation = (params) => (dispatch) => {
  return RoomService.createReservation(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const updateReservation = (params) => (dispatch) => {
  return RoomService.updateReservation(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const cancelReservation = (reservationId) => (dispatch) => {
  return RoomService.cancelReservation(reservationId)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const sendMailReservationStatus = (params) => (dispatch) => {
  return RoomService.sendMailReservationStatus(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const approveReservation = (params) => (dispatch) => {
  return RoomService.approveReservation(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const declineReservation = (params) => (dispatch) => {
  return RoomService.declineReservation(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const updateReservationTime = (params) => (dispatch) => {
  return RoomService.updateReservationTime(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const deleteReservation = (reservation_id) => (dispatch) => {
  return RoomService.deleteReservation(reservation_id)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const deleteManyReservation = (params) => (dispatch) => {
  return RoomService.deleteManyReservation(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const reservations = (params) => (dispatch) => {
  return RoomService.reservations(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const allReservations = (params) => (dispatch) => {
  return RoomService.allReservations(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const reservation = (reservationId) => (dispatch) => {
  return RoomService.reservation(reservationId)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const downloadExcel = () => (dispatch) => {
  return RoomService.excel()
    .then((response)=>{
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'rooms.xlsx');
      document.body.appendChild(link);
      link.click();
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const myStatus = (date_index) => (dispatch) => {
  return RoomService.myStatus(date_index)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const userStatus = (date_index, user_id) => (dispatch) => {
  return RoomService.userStatus(date_index, user_id)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const status = (date_index) => (dispatch) => {
  return RoomService.status(date_index)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};





