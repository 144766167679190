const regionData = {
  Korea: {
    Korea: '+82',
    'Other': ''
  },
  'North America': {
    'NA Office': '+1',
    SEA: '+1',
    SECA: '+1',
    'Other': ''
  },
  'South America': {
    'South America': '',
    'Other': ''
  },
  Europe: {
    'EU Office': '+44',
    'EO': '',
    'SEG (Germany)': '+49',
    'SEUK (UK)': '+44',
    'SEF (France)': '+33',
    'SEI': '',
    'SEIB (Spain)': '+34',
    'SEBN (Netherlands)': '+31',
    'SEBN-B (Belguim)': '+32',
    'SENA': '',
    'SEPOL (Poland)': '+48',
    'SEAS': '',
    'SEROM': '',
    'SECZ': '',
    'SEAD': '',
    'SEH': '',
    'SEH-S (Hungary)': '+36',
    'SEB': '',
    'SEGR': '',
    'SEIB-P (Portugal)': '+351',
    'SEI (Italy)': '+39',
    'Other': ''
  },
  China: {
    'China Office': '+86',
    'Other': ''
  },
  'South West Asia': {
    'SIEL-S': '+91',
    'Other': ''
  },
  'South East Asia': {
    'South East Asia': '',
    'Other': ''
  },
  'Africa': {
    'Africa': '',
    'Other': ''
  },
  CIS: {
    'CIS Office': '+7',
    'Other': ''
  },
  Japan: {
    SEJ: '+81',
    'Other': ''
  },
};

export default regionData;
