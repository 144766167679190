import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { isEmail } from 'validator';
import regionData from '@/data/regionData';
import mobileData from '@/data/mobileData';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Moment from 'react-moment';

import { createReservation, availableDateTime } from '@/actions/tour';

import history from '@/helpers/history';

const initGuest = {
  name: '',
  company: '',
  position: ''
}

const initValue = {
  date: '',
  time: '',
  language: '',
  currentBusinessIssues: '',
  inviteNumber: 1,
  inviteSamsungEmployeesNumber: 0,
  host: {
    department: '',
    executiveName: '',
    executivePosition: '',
    onSiteContactName: '',
    onSiteContactMobile: ''
  },
  pic: {
    firstName: '',
    lastName: '',
    region: '',
    organization: '',
    gbm: '',
    department: '',
    email: '',
    recv_email: '',
    countryCode: '',
    mobile: ''
  },
  site: {
    firstName: '',
    lastName: '',
    countryCode: '',
    mobile: ''
  },
  guests: [
    initGuest
  ],
  isRequireMobilityAssistance: false,
  mobilityAssistances: [],
}

export default function TourRerservation() {
  const dispatch = useDispatch();

  const { basePath } = useSelector((state) => state.common);

  const validationSchema = Yup.object().shape({
    date: Yup.string().required('Required field'),
    time: Yup.string().required('Required field'),
    language: Yup.string().required('Required field'),
    currentBusinessIssues: Yup.string().required('Required field'),
    inviteNumber: Yup.number().min(1).typeError('Amount must be a number').required('Required field'),
    inviteSamsungEmployeesNumber: Yup.number().min(0).typeError('Amount must be a number').required('Required field'),
    host: Yup.object().shape({
      department: Yup.string().required('Required field'),
      executiveName: Yup.string().required('Required field'),
      executivePosition: Yup.string().required('Required field'),
    }),
    pic: Yup.object().shape({
      firstName: Yup.string().max(40, 'must be at most 40 characters').required('Required field'),
      lastName: Yup.string().max(40, 'must be at most 40 characters').required('Required field'),
      region: Yup.string().required('Required field'),
      organization: Yup.string().required('Required field'),
      gbm: Yup.string().required('Required field'),
      department: Yup.string().required('Required field'),
      email: Yup.string().email('Email is Invalid').required('Required field'),
      recv_email: Yup.string().email('Email is Invalid'),
      countryCode: Yup.string().required('Required field'),
      mobile: Yup.string().max(15, 'must be at most 15 characters').required('Required field')
    }),
    site: Yup.object().shape({
      firstName: Yup.string().max(40, 'must be at most 40 characters').required('Required field'),
      lastName: Yup.string().max(40, 'must be at most 40 characters').required('Required field'),
      countryCode: Yup.string().required('Required field'),
      mobile: Yup.string().max(15, 'must be at most 15 characters').required('Required field')
    }),
    guests: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Required field'),
        company: Yup.string().required('Required field'),
        position: Yup.string().required('Required field')
      })
    ),
    isRequireMobilityAssistance: Yup.boolean(),
    mobilityAssistances: Yup.array().of(
      Yup.object().shape({
        information: Yup.string().required('Required field'),
        expectedService: Yup.array().min(1, 'Required field'),
        note: Yup.string(),
      })
    ),
  });

  const formOptions = {
    defaultValues: initValue,
    resolver: yupResolver(validationSchema)
  };

  
  const { register, control, handleSubmit, setValue, setError, reset, formState, watch } = useForm(formOptions);
  const { errors } = formState;
  const { fields: guests, append: guestAppend, update: guestUpdate, remove: guestRemove } = useFieldArray({ name: 'guests', control });
  const { fields: mobilityAssistances, append: mobilityAssistanceAppend, update: mobilityAssistanceUpdate, remove: mobilityAssistanceRemove, replace: mobilityAssistanceReplace } = useFieldArray({ name: 'mobilityAssistances', control });

  const [tourTimes, setTourTimes] = useState(null);

  const formData = watch();

  useEffect(() => {
    dispatch(availableDateTime())
    .then(res => {
      setTourTimes(res)
    })
  }, []);

  // useEffect(() => {
  //   setValue('inviteNumber', fields.length)
  // }, [fields.length])

  const addGuest = () => {
    guestAppend({
      name: '',
      company: '',
      position: ''
    })
  }

  const removeGuest = (index) => {
    guestRemove(index)
  }

  const addMobilityAssistance = () => {
    mobilityAssistanceAppend({
      information: '',
      expectedService: '',
      note: '',
    });
  }

  const removeMobilityAssistance = (index) => {
    mobilityAssistanceRemove(index)
  }

  const onSubmit = (data) => {
    dispatch(createReservation(data))
    .then((res) => {
      history.navigate(basePath ? `/${basePath}/admin/tour` : '/admin/tour', { replace: true })
    })
    .catch(message => {
      dispatch(availableDateTime())
      .then(res => {
        setTourTimes(res)
        setValue('date', '')
        setValue('time', '')
        setError('date', { message: 'Required field' });
        setError('time', { message: 'Required field' });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      })
      
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  const fillOutSampleData = () => {
    reset({
      date: formData.date,
      time: formData.time,
      language: formData.language,
      currentBusinessIssues: 'sample',
      inviteNumber: 1,
      inviteSamsungEmployeesNumber: 0,
      host: {
        department: 'sample',
        executiveName: 'sample',
        executivePosition: 'sample',
        onSiteContactName: 'sample',
        onSiteContactMobile: 'sample'
      },
      pic: {
        firstName: 'sample',
        lastName: 'sample',
        region: 'Korea',
        organization: 'Korea',
        gbm: 'sample',
        department: 'sample',
        email: 'sample@dinplus.com',
        countryCode: '+82',
        mobile: '1088888888'
      },
      site: {
        firstName: 'sample',
        lastName: 'sample',
        countryCode: '+82',
        mobile: '1088888888'
      },
      guests: [
        {
          name: 'sample',
          company: 'sample',
          position: 'sample'
        }
      ]
    })
  }

  return (
    <>
    <div className='container_adm adm-rsv'>
      <div className="container_body-tit">
        <h3>VIP Tour Reservation</h3>
        <span className="tit-desc">* All fields are required</span>
      </div>
      <section className="vip-reservation">
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
          <div className="vip_reservation_item">
            <div className="vip-reservation-tit mb40">
              <h4>Tour Request</h4>
              <span className="tit-desc"></span>
            </div>
            <div className='form_inner mb100'>
            <div className="form_item">
              <label htmlFor="language" className="list_label">Tour Language<span></span>투어 언어</label>
              <div className={`${errors.language?.message ? 'is-invalid' : ''}`} style={{width: '100%'}}>
                <select id={`language`} name={`language`} className='select_adm' {...register(`language`,{
                    onChange: (e)=>{
                      setValue('date', '')
                      setValue('time', '')
                    }})}>
                  <option value="">Please select</option>
                  <option value="Korean">Korean / 한국어</option>
                  <option value="English">English / 영어</option>
                </select>
                <div className='invalid-feedback'>{errors.language?.message}</div>
              </div>
            </div>
            {formData.language && <div className="form_item">
              <label htmlFor="vip_date" className="list_label">Date & Time<span></span>날짜 & 시간</label>
              <div className={`mr20 ${errors.date?.message ? 'is-invalid' : ''}`}>
                <select
                  id={`date`}
                  name={`date`}
                  className='select_adm'
                  {...register(`date`)}>
                  <option value=''>Please select</option>
                  {
                    tourTimes[formData.language] && Object.keys(tourTimes[formData.language]).map((value, mindex) => (
                      <option value={value} key={mindex}><Moment format='DD.MMM.YYYY'>{value}</Moment></option>
                    ))
                  }
                </select>
                <div className='invalid-feedback'>{errors.date?.message}</div>
              </div>
              {
                formData.date && (
                  <div className={`${errors.time?.message ? 'is-invalid' : ''}`}>
                    <select className='select_adm'
                      id={`time`}
                      name={`time`}
                      {...register(`time`)}>
                      <option value=''>Please select</option>
                      {
                        tourTimes[formData.language] && tourTimes[formData.language][formData.date].map((value, mindex) => (
                          <option value={value.time} key={mindex}>{value.time}</option>
                        ))
                      }
                    </select>
                    <div className='invalid-feedback'>{errors.time?.message}</div>
                  </div>
                )
              }
            </div>}
          </div>
          </div>
          <div className="buttons_center">
            <button type="button" className="btn-submit" onClick={()=>fillOutSampleData()}><span className="spsans">Sample Data</span></button>
          </div>
            <div className="vip-reservation-tit mb40">
              <h4>VIP Tour PIC</h4>
            </div>
            <div className='form_inner mb100'>      
              <div className='form_item'>
                <label htmlFor={`pic.firstName`} className='list_label' style={{marginRight:24}}>Name<span></span>이름</label>
                <div className={`mr20 ${errors.pic?.firstName?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id={`pic.firstName`}
                    name={`pic.firstName`}
                    //className='w322x'
                    {...register(`pic.firstName`,{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                      }})}
                    placeholder='First name'
                  />
                  <div className='invalid-feedback'>{errors.pic?.firstName?.message}</div>
                </div>
                <div className={`${errors.pic?.lastName?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id={`pic.lastName`}
                    name={`pic.lastName`}
                    //className='w322x'
                    {...register(`pic.lastName`,{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                      }})}
                    placeholder='Last name'
                  />
                  <div className='invalid-feedback'>{errors.pic?.lastName?.message}</div>
                </div>
              </div>
              <div className='form_item'>
                <label htmlFor={`pic.region`} className='list_label'>Region<span></span>지역</label>
                <div className={`${errors.pic?.region?.message ? 'is-invalid' : ''}`} style={{width: '100%'}}>
                  <select
                    id={`pic.region`}
                    className='select_adm'
                    name={`pic.region`}
                    {...register(`pic.region`)}>
                    <option value=''>Please select</option>
                    {
                      Object.keys(regionData).map((value, mindex) => (
                        <option value={value} key={mindex}>{value}</option>
                      ))
                    }
                  </select>
                  <div className='invalid-feedback'>{errors.pic?.region?.message}</div>
                </div>
              </div>
              {/* {
                formData?.pic?.region && (
                  <div className='form_item'>
                    <label htmlFor={`pic.organization`} className='list_label'>Organization<span></span>법인</label>
                    <div className={`${errors.pic?.organization?.message ? 'is-invalid' : ''}`}>
                      <select
                        id={`pic.organization`}
                        className='select_adm'
                        name={`pic.organization`}
                        {...register(`pic.organization`, {
                          onChange: (e) => {setValue(`pic.countryCode`, regionData[formData.pic.region][formData.pic.organization])},
                        })}>
                        <option value=''>Please select</option>
                        {
                          Object.keys(regionData[formData.pic.region]).map((value, mindex) => (
                            <option value={value} key={mindex}>{value}</option>
                          ))
                        }
                      </select>
                      <div className='invalid-feedback'>{errors.pic?.organization?.message}</div>
                    </div>
                  </div>
                )
              } */}
              <div className="form_item">
                <label htmlFor={`pic.organization`} className="list_label">Organization<span></span>법인</label>
                <div className={`${errors.pic?.organization?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id={`pic.organization`}
                    name={`pic.organization`}
                    {...register(`pic.organization`)}
                    placeholder='Enter your organization'
                  />
                  <div className='invalid-feedback'>{errors.pic?.organization?.message}</div>
                </div>
              </div>
              <div className="form_item">
                <label htmlFor={`pic.gbm`} className="list_label">Division<span></span>사업부</label>
                <div className={`${errors.pic?.gbm?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id={`pic.gbm`}
                    name={`pic.gbm`}
                    {...register(`pic.gbm`)}
                    placeholder='Enter your division'
                  />
                  <div className='invalid-feedback'>{errors.pic?.gbm?.message}</div>
                </div>
              </div>
              <div className="form_item">
                <label htmlFor={`pic.department`} className="list_label">Department<span></span>부서</label>
                <div className={`${errors.pic?.department?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id={`pic.department`}
                    name={`pic.department`}
                    {...register(`pic.department`)}
                    placeholder='Enter your department'
                  />
                  <div className='invalid-feedback'>{errors.pic?.department?.message}</div>
                </div>
              </div>
              <div className="form_item">
                <label htmlFor={`pic.email`} className="list_label">Email<span></span>이메일</label>
                <div className={`${errors.pic?.email?.message ? 'is-invalid' : ''}`} style={{width: '100%'}}>
                  <input
                    type='email'
                    id={`pic.email`}
                    name={`pic.email`}
                    {...register(`pic.email`,{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^0-9a-zA-Z-_.@]/g,'').toLowerCase();
                      }})}
                    placeholder='Enter your email'
                  />
                  <div className='invalid-feedback'>{errors.pic?.email?.message}</div>
                </div>
              </div>
              <div className="form_item form_item-ph">
                <label htmlFor={`countryCode`} className="list_label">Mobile Phone<span></span>연락처</label>
                <div className={`mr20 ${errors.pic?.countryCode?.message ? 'is-invalid' : ''}`} style={{width: 220}}>
                  <select
                    id={`pic.countryCode`}
                    name={`pic.countryCode`}
                    className='select_adm'
                    style={{width: 220}}
                    {...register(`pic.countryCode`)}>
                    <option value=''>Please select</option>
                    {
                      mobileData.map((value, mindex) => (
                        <option value={value} key={mindex}>{value}</option>
                      ))
                    }
                  </select>
                  <div className='invalid-feedback'>{errors.pic?.countryCode?.message}</div>
                </div>
                <div className={`${errors.pic?.mobile?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id={`pic.mobile`}
                    name={`pic.mobile`}
                    {...register(`pic.mobile`,{
                      onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^0-9]/g,'');
                      // if(e.target.value.length > 0 && e.target.value.substring(0,1) === '0') {
                      //   e.target.value = e.target.value.substring(1,e.target.value.length)
                      // }
                    }})}
                    placeholder='Enter your mobile number'
                  />
                  <div className='invalid-feedback'>{errors.pic?.mobile?.message}</div>
                </div>
              </div>
              <div className="form_item">
                <label htmlFor={`pic.recv_email`} className="list_label">Notification Email<span></span>알림 이메일</label>
                <div className={`${errors.pic?.recv_email?.message ? 'is-invalid' : ''}`} style={{width: '100%'}}>
                  <input
                    type='email'
                    id={`pic.recv_email`}
                    name={`pic.recv_email`}
                    {...register(`pic.recv_email`,{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^0-9a-zA-Z-_.@]/g,'').toLowerCase();
                      }})}
                    placeholder='Enter Notification Email (optional, default: account email)'
                  />
                  <div className='invalid-feedback'>{errors.pic?.email?.message}</div>
                </div>
              </div>
            </div>

            <div className="vip-reservation-tit mb40">
            <h4>On-site contact</h4>
          </div>
          <div className='form_inner mb100'>
            <div className='room-reservation-desc mb30'>
            - 신청자와 라스베가스 CES 현장 출장자가 다른 경우, 현장에서 컨택 가능한 출장자 정보 기입<br/>
            - Please enter the contact information of the PIC at the Las Vegas CES venue.
            </div>
            <div className='form_item'>
              <label htmlFor={`site.firstName`} className='list_label'>Name<span></span>이름</label>
              <div className={`mr20 ${errors.site?.firstName?.message ? 'is-invalid' : ''}`}>
                <input
                  type='text'
                  id={`site.firstName`}
                  name={`site.firstName`}
                  // className='w350x'
                  {...register(`site.firstName`,{
                    onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                    }})}
                  placeholder='First name'
                />
                <div className='invalid-feedback'>{errors.site?.firstName?.message}</div>
              </div>
              <div className={`${errors.site?.lastName?.message ? 'is-invalid' : ''}`}>
                <input
                  type='text'
                  id={`site.lastName`}
                  name={`site.lastName`}
                  // className='w350x'
                  {...register(`site.lastName`,{
                    onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                    }})}
                  placeholder='Last name'
                />
                <div className='invalid-feedback'>{errors.site?.lastName?.message}</div>
              </div>
            </div>
            <div className="form_item form_item-ph">
              <label htmlFor={`countryCode`} className="list_label">Mobile Phone<span></span>연락처</label>
              <div className={`mr20 ${errors.site?.countryCode?.message ? 'is-invalid' : ''}`} style={{width:220}}>
                <select
                  id={`site.countryCode`}
                  name={`site.countryCode`}
                  style={{width: 220}}
                  className='select_adm'
                  {...register(`site.countryCode`)}>
                  <option value=''>Please select</option>
                  {
                    mobileData.map((value, mindex) => (
                      <option value={value} key={mindex}>{value}</option>
                    ))
                  }
                </select>
                <div className='invalid-feedback'>{errors.site?.countryCode?.message}</div>
              </div>
              <div className={`${errors.site?.mobile?.message ? 'is-invalid' : ''}`}>
                <input
                  type='text'
                  id={`site.mobile`}
                  name={`site.mobile`}
                  {...register(`site.mobile`,{
                    onChange: (e)=>{
                    e.target.value = e.target.value.replace(/[^0-9]/g,'');
                    // if(e.target.value.length > 0 && e.target.value.substring(0,1) === '0') {
                    //   e.target.value = e.target.value.substring(1,e.target.value.length)
                    // }
                  }})}
                  placeholder='Enter your mobile number'
                />
                <div className='invalid-feedback'>{errors.site?.mobile?.message}</div>
              </div>
            </div>
          </div>

            <div className="vip-reservation-tit mb40">
              <h4>Samsung Host</h4>
              <span className="tit-desc"></span>
            </div>
            <div className='form_inner mb100'>
              <div className='room-reservation-desc mb30'>
                - 직접 호스팅하시는 부사장급/지역총괄급 이상 삼성 임원 정보 기입<br />
                - Please enter detail information of Samsung executive who will host the invitees, higher than EVP or Head of Regional Office position.
              </div>
              <div className="form_item">
                <label htmlFor="host_dept" className="list_label">Department<span></span>소속</label>
                <div className={`${errors.host?.department?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id={`host.department`}
                    name={`host.department`}
                    {...register(`host.department`,{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                      }})}
                    placeholder='Department'
                  />
                  <div className='invalid-feedback'>{errors.host?.department?.message}</div>
                </div>
              </div>
              <div className="form_item">
                <label htmlFor="host.executiveName" className="list_label">Executive Name<span></span>성함</label>
                <div className={`${errors.host?.executiveName?.message ? 'is-invalid' : ''}`}>
                    <input
                      type='text'
                      id={`host.executiveName`}
                      name={`host.executiveName`}
                      {...register(`host.executiveName`,{
                        onChange: (e)=>{
                          e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                        }})}
                      placeholder='Executive name'
                    />
                  <div className='invalid-feedback'>{errors.host?.executiveName?.message}</div>
                </div>
              </div>
              <div className="form_item">
                <label htmlFor="host.executivePosition" className="list_label">Executive Position<span></span>직위</label>
                <div className={`${errors.host?.executivePosition ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id={`host.executivePosition`}
                    name={`host.executivePosition`}
                    {...register(`host.executivePosition`,{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                      }})}
                    placeholder='Executive position'
                  />
                  <div className='invalid-feedback'>{errors.host?.executivePosition?.message}</div>
                </div>
              </div>
            </div>

            <div className="vip-reservation-tit mb40">
              <h4>VIP Guests</h4>
            </div>
            <div className='form_inner bb mb30'>
            <div className='room-reservation-desc mb30'>
            - 입력 주신 인원 수를 바탕으로 투어 리시버/헤드셋이 준비되므로 정확한 방문객 숫자 기입 필요<br/>
            - 쾌적한 관람을 위해 투어 인원 15인 제한<br/>
            - Tour receivers and headsets will be prepared based on the number of people entered below. Please enter exact number of visitors.<br/>
            - Tours are limited to 15 people to maintain a pleasant tour experience for your guests.
            </div>
            <div className="form_item mb30">
              <label htmlFor="num_invitees" className="num_invite list_label" style={{marginRight: 87}}>
              Number of Invitees<span></span>초대받은 인원 수
              </label>

              <div style={{width: '100%'}} className={`${errors.inviteNumber?.message ? 'is-invalid' : ''}`} style={{width: '100%'}}>
                {/* <select
                  id={`inviteNumber`}
                  name={`inviteNumber`}
                  {...register(`inviteNumber`,{
                    onChange: (e)=>{
                      // if(e.target.value > fields.length){
                      //   Array.from(Array(e.target.value - fields.length).keys()).forEach(()=>{
                      //     addGuest();
                      //   })
                      // } else {
                      //   for (let index = formData.guests.length-1; index >= e.target.value; index--) {
                      //     console.log(index)
                      //     removeGuest(index);
                      //   }
                      // }
                    }})}>
                  {
                    Array.from(Array(10).keys()).map(i=>(
                      <option value={i+1}>{i+1}</option>
                    ))
                  }
                </select> */}
                <input
                  type='number'
                  id={`inviteNumber`}
                  name={`inviteNumber`}
                  {...register(`inviteNumber`,{
                    onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^0-9]/g,'');
                    }})}
                  placeholder='Enter Number of Invitees'
                  min={1}
                  max={99}
                />
                <div className='invalid-feedback'>{errors.inviteNumber?.message}</div>
              </div>
              {/*<button className="btn-add" type="button"><span className="spsans">Add</span></button>*/}
            </div>
            <div className="form_item mb30">
              <label htmlFor="num_invitees" className="num_invite list_label" style={{width: 348, minWidth: 348, maxWidth: 348, marginRight: 24}}>
              Number of Samsung employees<span></span>동행 삼성 임직원 수
              </label>

              <div style={{width:'100%'}} className={`${errors.inviteSamsungEmployeesNumber?.message ? 'is-invalid' : ''}`}>
                <input
                  type='number'
                  id={`inviteSamsungEmployeesNumber`}
                  name={`inviteSamsungEmployeesNumber`}
                  {...register(`inviteSamsungEmployeesNumber`,{
                    onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^0-9]/g,'');
                    }})}
                  placeholder='Enter Number of Samsung employees'
                  min={0}
                  max={99}
                />
                <div className='invalid-feedback'>{errors.inviteSamsungEmployeesNumber?.message}</div>
              </div>
              {/*<button className="btn-add" type="button"><span className="spsans">Add</span></button>*/}
            </div>
            <div className='room-reservation-desc mb40'>
              - 최소 한 분의 대표 VIP 게스트 정보 입력 필요<br />
              - Required at least one, representative VIP guest information.
            </div>
             <div className="vip_invitees mb40">
                {
                  guests.map((item, i)=>(
                    <div className="vip_invitee mb30" key={i}>
                      <p>No{i+1}.</p>
                      <div className={`mr20 ${errors.guests?.[i]?.name?.message ? 'is-invalid' : ''}`}>
                        <input
                          type='text'
                          id={`guests[${i}]name`}
                          name={`guests[${i}]name`}
                          {...register(`guests.${i}.name`,{
                            onChange: (e)=>{
                              e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                            }})}
                          placeholder='Name of the guest'
                        />
                        <div className='invalid-feedback'>{errors.guests?.[i]?.name?.message}</div>
                      </div>
                      <div className={`mr20 ${errors.guests?.[i]?.company?.message ? 'is-invalid' : ''}`}>
                        <input
                          type='text'
                          id={`guests[${i}]company`}
                          name={`guests[${i}]company`}
                          {...register(`guests.${i}.company`,{
                            onChange: (e)=>{
                              e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                            }})}
                          placeholder='Company of the guest'
                        />
                        <div className='invalid-feedback'>{errors.guests?.[i]?.company?.message}</div>
                      </div>
                      <div className={`mr20 ${errors.guests?.[i]?.position?.message ? 'is-invalid' : ''}`}>
                        <input
                          type='text'
                          id={`guests[${i}]position`}
                          name={`guests[${i}]position`}
                          {...register(`guests.${i}.position`,{
                            onChange: (e)=>{
                              e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                            }})}
                          placeholder='Position of the guest'
                        />
                        <div className='invalid-feedback'>{errors.guests?.[i]?.position?.message}</div>
                      </div>
                      <button className="btn-remove" type="button" style={{width:42,visibility: i > 0 ? 'visible' : 'hidden'}} onClick={()=>removeGuest(i)}></button>
                    </div>
                  ))
                }
              </div>
            <span class="bar mb30"></span>
            </div>
          {guests.length < 15 && <div className="buttons_center mb60">
            <button type="button" className="btn-plus" onClick={()=>addGuest()}></button>
          </div>}
          <div className='form_inner bb mb100'>
                <div className="form_item mb40" style={{flexDirection:'column'}}>
                  <label htmlFor="currentBusinessIssues" className={`current_issues list_label`} style={{width:'100%',maxWidth:'100%',marginBottom: 24,marginRight:0}}>
                    - 당사와의 관계 또는 연관된 비즈니스 현안 <i>(예- 북미 디스플레이 사업 당사 제1거래선)</i><br/>
                    - Business relations to Samsung or relevant business issues <i>(e.g. The 1st partner on display business in North America)</i>
                  </label>

                  <div className={`${errors.currentBusinessIssues?.message ? 'is-invalid' : ''}`}>
                    <input
                      type='text'
                      id={`currentBusinessIssues`}
                      name={`currentBusinessIssues`}
                      {...register(`currentBusinessIssues`)}
                  placeholder='Please fill in the blank'
                      maxLength={255}
                    />
                    <div className='invalid-feedback'>{errors.currentBusinessIssues?.message}</div>
                  </div>
                </div>
              </div>
              <div className="vip-reservation-tit">
            <h4>Additional accommodations</h4>
            <span className="tit-desc"></span>
          </div>
          
          <div className='form_inner mb30'>
            <div className='room-reservation-desc'>
            - 투어 중간에 계단을 이용하는 2층 코스가 포함되어 있으므로, 거동이 불편하시거나 별도의 도움이 필요하신 게스트가 있는 경우 기입<br/>
            - Please be aware that our booth has 2-floor access with stairs. If you have any guests in need of assistance, please enter the below.
            </div>
              <div className="form_item mb40">
                <label htmlFor="isRequireMobilityAssistance" className="list_label" style={{marginRight: 24}}>Mobility assistance<span></span>이동 지원 서비스</label>
                  <select id={`isRequireMobilityAssistance`} name={`isRequireMobilityAssistance`} {...register(`isRequireMobilityAssistance`,{
                          onChange: (e)=>{
                            mobilityAssistanceReplace(e.target.value === 'true' ? {
                              information: '',
                              expectedService: '',
                              note: '',
                            } : [])
                            setValue('isRequireMobilityAssistance', e.target.value === 'true')                          
                          }})}>
                      <option value={false}>Not Required</option>
                      <option value={true}>Required</option>
                    </select>
                    {/* 메모: Required를 선택하면 아래 필드가 나타나는 구조입니다. */}
              </div>
              {
                formData.isRequireMobilityAssistance && <>
                {
                 mobilityAssistances.map((item, i)=>( <> 
                  {i > 0 && <div className='assitance_added' key={i} style={{paddingTop: '50px'}}>
                      <button className="btn-remove" type="button" onClick={()=>removeMobilityAssistance(i)}></button>
                  </div>}
              <div className="form_item mb40">
                {/* 메모: 하기는 추가된 데이터입니다. 값 변경 필요합니다 */}
                <label htmlFor={`mobilityAssistances[${i}]information`} className="list_label" style={{marginRight: 24}}>Guest Information<span></span>
                게스트 정보 입력<br/>
                - 투어 그룹 현장 방문 시 지원 필요한 Guest 확인용<br/>
                - To ensure the assistance of guests who will require accommodations for the tour.
                </label>
                <div className={`${errors.mobilityAssistances?.[i]?.information?.message ? 'is-invalid mr20' : 'mr20'}`}>
                <input
                          type='text'
                          id={`mobilityAssistances[${i}]information`}
                          name={`mobilityAssistances[${i}]information`}
                          {...register(`mobilityAssistances.${i}.information`,{
                            onChange: (e)=>{
                              e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                          }})}
                          placeholder='Name of the guest'
                          maxLength={255}
                        />
                <div className='invalid-feedback'>Required field</div>
                </div>
              </div>
              <div className="form_item mb40">
                {/* 메모: 하기는 추가된 데이터입니다. 값 변경 필요합니다 */}
                <label htmlFor={`mobilityAssistances[${i}]expectedService`} className="list_label" style={{letterSpacing: '-1px',marginRight:24}}>Request for assistance<span></span><br/>요청사항</label>
                <div className={`${errors.mobilityAssistances?.[i]?.expectedService?.message ? 'is-invalid' : ''} mt10 checkbox`}>
                <input type="checkbox" className='mr10' id={`mobilityAssistances[${i}]expectedService_1`} name={`mobilityAssistances[${i}]expectedService`} {...register(`mobilityAssistances.${i}.expectedService`)} value={'Hoist 승강기'}/><label htmlFor={`mobilityAssistances[${i}]expectedService_1`} className='mr30'>Hoist 승강기</label>
                <input type="checkbox" className='mr10' id={`mobilityAssistances[${i}]expectedService_2`} name={`mobilityAssistances[${i}]expectedService`} {...register(`mobilityAssistances.${i}.expectedService`)} value={'Assistant 보조 인력'}/><label htmlFor={`mobilityAssistances[${i}]expectedService_2`} className='mr30'>Assistant 보조 인력</label>
                <input type="checkbox" className='mr10' id={`mobilityAssistances[${i}]expectedService_3`} name={`mobilityAssistances[${i}]expectedService`} {...register(`mobilityAssistances.${i}.expectedService`)} value={'Just additional time 시간 여유'}/><label htmlFor={`mobilityAssistances[${i}]expectedService_3`} className='mr30'>Just additional time 시간 여유</label>
                <input type="checkbox" className='mr10' id={`mobilityAssistances[${i}]expectedService_4`} name={`mobilityAssistances[${i}]expectedService`} {...register(`mobilityAssistances.${i}.expectedService`)} value={'Others 기타'}/><label htmlFor={`mobilityAssistances[${i}]expectedService_4`} className='mr30'>Others 기타</label>
                <div className='invalid-feedback'>Required field</div>

                </div>
              </div>
              <div className="form_item" style={{width:'100%'}}>
                {/* 메모: 하기는 추가된 데이터입니다. 값 변경 필요합니다 */}
                <label htmlFor={`mobilityAssistances[${i}]note`} className="list_label" style={{marginRight: 24}}>Additional note or comment<span></span>비고</label>
                <input
                          type='text'
                          id={`mobilityAssistances[${i}]note`}
                          name={`mobilityAssistances[${i}]note`}
                          {...register(`mobilityAssistances.${i}.note`)}
                          placeholder='Optional'
                          maxLength={255}
                        />
              </div>
                <p className='mb30'><span className='ft_blue'>- 게스트가 이전에 동일한 상황에서 제공받았던 서비스 등에 대한 기술</span> (e.g. 2명 이상의 보조 인력 필요)
                <br/><span className='ft_blue'>- Describe any accommodations guest typically received in the past for staircase access</span> (e.g. Assistance from more than one person)</p>
                <span className='bar' style={{backgroundColor:'#D3D3D3'}}></span>
               </>)) 
              }
              <div className="buttons_center" style={{margin: '0 auto 30px'}}>
                <button type="button" className="btn-plus" onClick={()=>addMobilityAssistance()}></button>
              </div>
            </>
            }
          </div>
          <span class="bar mt30 mb60"></span>
          <div className="buttons_center">
            <Link to={basePath ? `/${basePath}/admin/tour` : "/admin/tour"} replace={true}><button type="button" className="btn-cancel mr10"><span className="spsans">Cancel</span></button></Link>
            <button type="submit" className="btn-submit"><span className="spsans">Submit</span></button>
          </div>
        </form>
      </section>
    </div>
    </>
  );
}