import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import history from '@/helpers/history';
import moment from 'moment-timezone';
import { isEmail } from 'validator';
import iconRegister from '@/assets/images/img_register.png';
import iconPassword from '@/assets/images/img_password.png';

import { login, logout, forgotPassword } from "@/actions/auth";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        <FormattedMessage id='requiredField' defaultMessage="Required field" />
      </div>
    );
  }
};

const requiredEmail = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        <FormattedMessage id='requiredField' defaultMessage="Required field" />
      </div>
    );
  } else if(!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        <FormattedMessage id='Invalid email address' defaultMessage="Invalid email address" />
      </div>
    );
  }
};

export default function Home() {
  const form = useRef();
  const checkBtn = useRef();
  
  const [forgotPasswordUserId, setForgotPasswordUserId] = useState('');
  const [forgotPasswordEmailDomain, setForgotPasswordEmailDomain] = useState('samsung.com');
  
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPasswordModal, setIsForgotPasswordModal] = useState(false);
  const [isUseItNoticeModal, setIsUseItNoticeModal] = useState(false);
  const [isReservationFull, setIsReservationFull] = useState(false);
  const [modal, setModal] = useState(true);
  const [emailDatalist, setEmailDatalist] = useState([]);

  const currentUser = useSelector((state) => state.user);
  const { basePath } = useSelector((state) => state.common);

  const dispatch = useDispatch();

  const onChangeUserId = (e) => {
    const value = e.target.value;
    setUserId(value);
  };

  const onChangeForgorPasswordUserId = (e) => {
    const value = e.target.value;
    setForgotPasswordUserId(value);
  };

  const onChangeForgorPasswordEmailDomain = (e) => {
    const value = e.target.value;
    setForgotPasswordEmailDomain(value);
  };

  const onChangePassword = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setIsLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(userId, password))
        .then(res => {
          if(res.user.isVerified){
            // history.navigate(`/`)
          }else{
            history.navigate(`/room/verify-email`, { replace: true })
          }
        })
        .catch(message => {
          toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
          });
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const handleFindPassword = () => {
    if(!forgotPasswordUserId){
      toast.error(<FormattedMessage id={`messages.error.notFoundForgotPasswordUserId`} defaultMessage={'Please enter your ID'} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
      return;
    }

    dispatch(forgotPassword(forgotPasswordUserId, forgotPasswordEmailDomain))
      .then(res => {
        toast.success('Temporary password has been issued.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
        });
        setIsForgotPasswordModal(false);
      })
      .catch(message => {
        toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
        });
      });
  }

  // useEffect(()=>{
  //   if(moment().tz("Asia/Seoul").isAfter('2022-12-30T10:00:00.000Z')){
  //     setIsReservationFull(true)
  //   }
  // }, [])

  useEffect(()=>{
    const emails = [
      'samsung.com',
      'partner.samsung.com',
      'sea.samsung.com',
      'partner.sea.samsung.com',
      'smartthings.com'
    ]

    if (userId.length > 0 && userId.indexOf('@') < 0) {
      setEmailDatalist(emails.map(emailDomain=>`${userId}@${emailDomain}`));
    } else {
      setEmailDatalist([]);
    }
  }, [userId])

  return (
    <>
   
      {/* {(moment().tz("Asia/Seoul").isBefore('2024-01-09T01:00:00.000Z') && modal) && <div className="modal_openevent">
        <div className="openevent_box">
          <div className="inner_title sssb">
            CES <span>2024</span>
          </div>
          <div className="inner_text ssone">
          This site is not officially opened.<br />
          Your reservation will be cancled during test period.
          </div>
          <div className="inner_text2 ssone">
            Official reservation Open<br />
            <span>01th Aug(Tue), 10:00 KST</span>
          </div>
        </div>
      </div>} */}
      <div className="index_container">
        <h1 className="index_title">CES <span className="co">2024</span><p>Reservation System</p></h1>
        {/* ${basePath.replace('/','')} */}
        <ul className={`index_inenr`}>
          <li>
            <div className="index_list" onClick={()=>currentUser?.isLoggedIn ? history.navigate('/ticket/information', {replace: true}) : setIsUseItNoticeModal(true)}>
              <div className="index_list-text" style={{display: 'flex', alignItems: 'center', height: '96px'}}>
                <h2>Ticket</h2>
              </div>
              <span className="btn_enter"></span>
            </div>
          </li> 
          <li>
            <div className="index_list" onClick={()=>currentUser?.isLoggedIn ? history.navigate('/room/information', {replace: true}) : setIsUseItNoticeModal(true)}>
              <div className="index_list-text">
                <h2>Business Lounge</h2>
              </div>
              <span className="btn_enter"></span>
            </div>
          </li> 
          <li>
            <div className="index_list" onClick={()=>currentUser?.isLoggedIn ? history.navigate('/viptour/information', {replace: true}) : setIsUseItNoticeModal(true)}>
              <div className="index_list-text" style={{display: 'flex', alignItems: 'center', height: '96px'}}>
                <h2>VIP Tour</h2>
              </div>
              <span className="btn_enter"></span>
            </div>
          </li> 
          {!currentUser?.isLoggedIn && (<li className="login_no_board" style={{width:'360px'}}>
            <Form onSubmit={handleLogin} ref={form} autoComplete="off">

            <div className="login_wrap" style={{width:'360px'}}>
              <div className="login_wrap-tit">
                <a>LOGIN</a>
                  <div className="login-links">
                    {
                      // moment().tz("Asia/Seoul").isAfter('2023-08-01T01:00:00.000Z') 
                      true && <Link to={basePath ? `/${basePath}/register` : `/register`} className="ssone">Register</Link>}
                    <button type="button" className="ssone" onClick={()=>setIsForgotPasswordModal(true)}>Forgot Password</button>
                  </div>
              </div>
              <div className="login_wrap-inputs" style={{alignItems:'baseline', position:'relative'}}>
                  <Input
                    type="text"
                    name="userId"
                    value={userId}
                    autoComplete="off"
                    placeholder="Enter your email"
                    onChange={onChangeUserId}
                    validations={[requiredEmail]}
                  />
                  {emailDatalist.length > 0 && <div className="user_id_datalist">
                    <ul>
                      {
                        emailDatalist.map(email=><li key={email} onClick={()=>setUserId(email)}>{email}</li>)
                      }
                    </ul>
                  </div>}
                  <Input
                    type="password"
                    name="password"
                    value={password}
                    autoComplete="off"
                    placeholder="Password"
                    onChange={onChangePassword}
                    validations={[required]}
                  />
                </div>
                <button type="submit" className="btn-login">LOGIN</button>
                
            </div>
            <CheckButton style={{ display: "none" }} ref={checkBtn} />

            </Form>

          </li>)
          }
          {currentUser?.isLoggedIn && (
            <li style={{width:'360px', height: '173px'}}>
              <div className="logout_wrap" style={{width:'360px'}}>
                <ul style={{width:'360px'}}>
                  <li>{currentUser.user.email}</li>
                  <li>
                    <button type="button" className="ssone mr10" onClick={()=>dispatch(logout())}>Logout</button>
                    {/* <Link to="/mypage">My page</Link> */}
                    { basePath ? <Link to={`/${basePath}/mypage`} className="ssone">My Page</Link> : <Link to="/mypage"  className="ssone">My Page</Link>}
                  </li>
                </ul>
                <div className="logout_name sssb">
                  <span>Welcome,</span> {currentUser.user.firstName} {currentUser.user.lastName}
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
      <div className="modal fade in" id="modalFindPw" style={{display: isForgotPasswordModal ? 'block' : 'none'}}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-content-p0">
              <div className="modal-header">
                <button type="button" className="btn-modalclose" onClick={()=>setIsForgotPasswordModal(false)}> </button>
              </div>
              <div className="modal-body">
                <div className="form">
                  <h3 className="mo_h3">Forgot your password?<br/>비밀번호 재설정</h3>
                  <p className="mb5">Please enter your email address below.</p>{/* <br/>to receive a new password. */}
                  <p className="mb30">새로운 비밀번호 발급을 위해<br />가입 시 입력한 이메일주소를 기입해 주십시오.</p>

                  <div className="forgot_input">
                    <input type="text" placeholder="" onChange={onChangeForgorPasswordUserId}/>
                    <span>@</span>
                    <select onChange={onChangeForgorPasswordEmailDomain}>
                      <option value='samsung.com'>samsung.com</option>
                      <option value='partner.samsung.com'>partner.samsung.com</option>
                      <option value="sea.samsung.com">sea.samsung.com</option>
                      <option value="partner.sea.samsung.com">partner.sea.samsung.com</option>
                      <option value="smartthings.com">smartthings.com</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn-send" onClick={()=>handleFindPassword()}>Send</button>
              </div>
            </div>
          </div>
      </div>
      <div className="modal fade in" id="modalCancel" style={{display: isUseItNoticeModal ? 'block' : 'none'}}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-content-p0">
              <div className="modal-header">
                <button type="button" className="btn-modalclose" onClick={()=>setIsUseItNoticeModal(false)}> </button>
              </div>
              <div className="modal-body">
                <h3 className="mo_h3">This is a members-only service.<br/>회원 전용 서비스 입니다.</h3>
                <br />
                <p>You are required to sign up and login first.<br/>회원 가입 및 로그인 후 이용하시기 바랍니다.</p>
              </div>
              <div className="modal-footer">
                {moment().tz("Asia/Seoul").isAfter('2022-11-28T03:00:00.000Z') && <Link to="/register"><button type="button" className="btn-black wb50 mr20">Register</button></Link>}
                &nbsp;
                <button type="button" className="btn-done_200 wb50" onClick={()=>setIsUseItNoticeModal(false)}>Login</button>
                </div>
            </div>
          </div>
      </div>
      <div className='modal fade in' id='modalFull' style={{display: isReservationFull ? 'block' : 'none'}}>
      <div className='modal-dialog modal-dialog-centered modal-dialog_lg'>
        <div className='modal-content modal-content-p0'>
          <div className='modal-body'>
            <p style={{paddingBottom:'1em'}}><strong>Sorry, we are closed.</strong></p>
            <p style={{lineHeight:'1.7'}}>Reservation system is early-closed since we received too many requests.<br/>
              In case of English tour, we still have several slots on 7th and 8th,<br/>only few on 5th and 6th.<br/>
              If you would like to make reservation,<br/>please send email to Soojeong Kim (<a href="mailto:soozeong.kim@samsung.com" className='ssone' style={{color:'var(--color-blue)'}}>soozeong.kim@samsung.com</a>)</p>
            <span style={{display:'inline-block',width:'30px',height:'1px',margin:'25px 0',backgroundColor:'#ddd'}}></span>
            <p style={{paddingBottom:'1em'}}><strong>사이트 신청 마감 안내</strong></p>
            <p style={{lineHeight:'1.7'}}>투어 신청이 많아 사이트 신청을 조기 마감합니다.<br/>혼선을 방지하기 위해 앞으로의 예약 문의는 메일로만 (<a href="mailto:soozeong.kim@samsung.com" className='ssone' style={{color:'var(--color-blue)'}}>soozeong.kim@samsung.com</a>) 가능하며,
            <br/>국문 투어의 경우 7일, 8일 약간의 슬롯이 남아있고, 5일, 6일에는 풀부킹으로<br/>예약이 불가하니 문의 주실 때 참고 부탁드립니다.</p>
          </div>
          <div className='modal-footer'>
            <button type="button" className="btn-send" onClick={()=>setIsReservationFull(false)}>Done</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
