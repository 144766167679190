export const jobs = [
  'Marketing',
  'Marketing/Sales',
  'Sales',
  'Sales/Management',
  'R&D',
  'Design',
  'Management',
  'Exhibition Staff',
  'Etc',
];

export const meetingTypes = [
  'Marketing',
  'Sales',
  'R&D',
  'Design',
  'Management',
  'Exhibition Staff',
  'Etc',
];