import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { isEmail } from 'validator';
import history from '@/helpers/history';
import LogoIcon from '@/assets/images/logo-w.svg';

import { login } from "@/actions/auth";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        <FormattedMessage id='RequiredField' defaultMessage="Required field" />
      </div>
    );
  }
};

const requiredEmail = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        <FormattedMessage id='requiredField' defaultMessage="Required field" />
      </div>
    );
  } else if(!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        <FormattedMessage id='Invalid email address' defaultMessage="Invalid email address" />
      </div>
    );
  }
};

export default function AdminLogin() {
  const form = useRef();
  const checkBtn = useRef();

  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [emailDatalist, setEmailDatalist] = useState([]);

  const { basePath } = useSelector((state) => state.common);

  const dispatch = useDispatch();

  const onChangeUserId = (e) => {
    const value = e.target.value;
    setUserId(value);
  };

  const onChangePassword = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(userId, password))
        .then(res => {
          if(res.user.isVerified){
            if(['admin'].indexOf(res.user.userId) > -1){
              history.navigate('/admin/member', { replace: true })
            } else if(['soozeong.kim', 'vipadmin'].indexOf(res.user.userId) > -1){
              history.navigate('/admin/tour', { replace: true })
            } else if(['ticketadmin'].indexOf(res.user.userId) > -1){
              history.navigate('/admin/ticket', { replace: true })
            } else if(['j.fairbanks','z.lasola-smi','lena.germand','roomadmin'].indexOf(res.user.userId) > -1){
              history.navigate('/admin/room', { replace: true })
            } else {
              history.navigate(basePath ? `/${basePath}` : '/', { replace: true })
            }
          }else{
            history.navigate(basePath ? `/${basePath}/verify-email` : `/verify-email`, { replace: true })
          }
        })
        .catch(message => {
          toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
          });
        });
    }
  };

  useEffect(()=>{
    const emails = [
      'samsung.com',
      'partner.samsung.com',
      'sea.samsung.com',
      'partner.sea.samsung.com',
      'smartthings.com'
    ]

    if (userId.length > 0 && userId.indexOf('@') < 0) {
      setEmailDatalist(emails.map(emailDomain=>`${userId}@${emailDomain}`));
    } else {
      setEmailDatalist([]);
    }
  }, [userId])

  return (
    <>
      <div className='adm_wrap'>
        <div className='adm_login_logo'>
          <img src={LogoIcon} alt='SAMSUNG' />
        </div>
        <div className='adm_contents'>
          <div className='adm_title'>
            <h1 className="index_title adm">CES <span className="co">2024</span><p>Reservation System </p></h1>
          </div>
          <div className='login_wrap adm'>
            <Form onSubmit={handleLogin} ref={form}>
            <div className='login_wrap-inputs' style={{position:'relative'}}>
              <label className="adm_label mr20" htmlFor="userId" >
                <p className="mb10">ID</p>
                <Input
                  type="text"
                  name="userId"
                  autoComplete="off"
                  value={userId}
                  placeholder="Enter your email"
                  onChange={onChangeUserId}
                  validations={[requiredEmail]}
                />
              </label>
              {emailDatalist.length > 0 && <div className="user_id_datalist" style={{width: '330px', top: '75px', color: 'black'}}>
                <ul>
                  {
                    emailDatalist.map(email=><li key={email} onClick={()=>setUserId(email)}>{email}</li>)
                  }
                </ul>
              </div>}
              <label className="adm_label" htmlFor="userId" style={{marginRight:24}} >
                <p className="mb10">Password</p>
                <Input
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required]}
                />
              </label>
              <CheckButton type="submit" className='btn-login adm' ref={checkBtn}>Login</CheckButton> 
              {/* <button type="submit" className='btn-login' ref={checkBtn}>Login</button> */}
            </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
