const companyData = {
  Samsung: [
    'GMO',
    'VD / VD EBT',
    'DA',
    'MX',
    'DS',
    'Comm',
    'SR',
    'etc',
    'Other'
  ],
  Cheil: [
    'GMO',
    'VD / VD EBT',
    'DA',
    'MX',
    'DS',
    'Comm',
    'SR',
    'etc',
    'Other'
  ],
  'Third party(협력사)': [    
    'GMO',
    'VD / VD EBT',
    'DA',
    'MX',
    'DS',
    'Comm',
    'SR',
    'etc',
    'Other'
  ]
}

export default companyData;
