import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { isEmail } from 'validator';
import regionData from '@/data/regionData';
import mobileData from '@/data/mobileData';
import companyData from '@/data/companyData';
import iconWarning from '@assets/images/icon_warning.png';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import uuid from 'react-uuid'
import history from '@/helpers/history';

import { me } from '@/actions/auth';
import { createTicket, existsEmail } from '@/actions/ticket';

const initValue = {
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  division: '',
  region: '',
  organization: '',
  countryCode: '',
  mobile: '',
  checkEmail: false
}

export default function TicketRequest() {
  const dispatch = useDispatch();
//min(40, 'must be at least 40 characters')
  const validationSchema = Yup.object().shape({
    tickets: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().max(40, 'must be at most 40 characters').required('Required field'),
        lastName: Yup.string().max(40, 'must be at most 40 characters').required('Required field'),
        email: Yup.string().email('Email is Invalid').required('Required field'),
        checkEmail: Yup.boolean().required('Please click the ‘Check’ button').oneOf([true], 'Please click the ‘Check’ button'),
        company: Yup.string().required('Required field'),
        division: Yup.string().required('Required field'),
        region: Yup.string().required('Required field'),
        organization: Yup.string().required('Required field'),
        countryCode: Yup.string().required('Required field'),
        mobile: Yup.string().max(15, 'must be at most 15 characters').required('Required field')
      })
    )
  });

  const formOptions = {
    defaultValues: {
      orno: uuid(),
      tickets: [ initValue ]
    },
    resolver: yupResolver(validationSchema)
  };

  const { register, control, handleSubmit, setValue, setError, reset, formState, watch, getValues } = useForm(formOptions);
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({ name: 'tickets', control });

  const [isCheckEmail, setIsCheckEmail] = useState(false);
  const [isSameProfile, setIsSameProfile] = useState(false);
  const [isTicketNotice, setIsTicketNotice] = useState(false);
  const [isDupEmail, setIsDupEmail] = useState(false);
  const [isDupEmailInList, setIsDupEmailInList] = useState(false);
  const [dupEmails, setDupEmails] = useState('');

  const tickets = watch('tickets');

  console.log(errors)

  useEffect(() => {
    if(isSameProfile){
      dispatch(me())
      .then((res) => {
        setValue(`tickets[0]`, {
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.email,
          company: res.company,
          division: res.division,
          region: res.region,
          organization: res.organization,
          countryCode: res.countryCode,
          mobile: res.mobile,
          checkEmail: false
        }, { shouldValidate: true })

        const values = getValues();    

        reset(values);
      })
    } else {
      setValue(`tickets[0]`, {
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        division: '',
        region: '',
        organization: '',
        countryCode: '',
        mobile: '',
        checkEmail: false
      })

      const values = getValues();    

      reset(values);
    }
  }, [isSameProfile])

  const appendTicket = () => {
    append(initValue);
  }

  const deleteTicket = (index) => {
    remove(index);
  }

  const onSubmit = (data) => {
    setIsTicketNotice(true);
  }

  const onCreateTicket = () => {
    dispatch(createTicket({tickets}))
    .then((res) => {
      history.navigate('/ticket/ticket-list', { replace: true })
    })
    .catch(message => {
      if(message.indexOf('dup key') && message.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)?.[0]){
        setIsDupEmail(true);
        setDupEmails(message.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)?.[0])
      } else {
        toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
        });
      }
    });
  }

  const checkEmail = (index) => {
    if(!isEmail(tickets[index].email) ) {
      setError(`tickets[${index}].email`, { message: 'Please enter email' });
      toast.error('Please enter email', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
      return;
    } else {
      setIsDupEmailInList(false);
      setDupEmails('');
      setError(`tickets[${index}].email`, { message: '' });
    }

    for(let i=0; i < tickets.length; i++) {
      if(i !== index && tickets[i].email === tickets[index].email){
        setIsDupEmail(true);
        setIsDupEmailInList(true);
        setDupEmails(tickets[i].email);
        return;
      }
    }

    dispatch(existsEmail(tickets[index].email))
    .then((res) => {
      if(res.isExistsEmail){
        setError(`tickets[${index}].email`, { message: 'Duplicate email registration is prohibited\n(Proxy request is available)' });
        setIsDupEmail(true);
      }else{
        setValue(`tickets.${index}.checkEmail`, true, { shouldValidate: true })
      }
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  const onChangeSameProfileCheckbox = (e) => {
    if(e.target.checked){
      setIsSameProfile(true);
    }else{
      setIsSameProfile(false);
    }
  }

  return (
    <>
      <div className="container_top ticket_top">
        <div className="top-info top-info_cn">
          <h2 className="top-title">Ticket Request</h2>
        </div>
      </div>
      <div className='container_body-tit mb60'>
        <h3></h3>
        <span className='tit-desc'>* All fields are required</span>
      </div>
      <section className='ticket-request'>
        <form className='form ticket_form' onSubmit={handleSubmit(onSubmit)}>
          {
            fields.map((item, i)=>{
              return (
                <div key={item.id}>
                  {
                    i === 0 ? (
                      <div className='request_item-bar'>
                        <h4>Ticket Request</h4>
                          <div className='checkbox'>
                            <input id='same' type='checkbox' onChange={onChangeSameProfileCheckbox} className='mr10' checked={isSameProfile} />
                            <label htmlFor='same'>Same as my profile</label>
                          </div>
                      </div>
                    ) : (
                      <div className='request_item-bar'>
                        <div className='request_item-bar_no'>No.{i+1}</div>
                        <div className='request-bar-buttons'>
                          <button type='button' className='btn-delete' onClick={()=>deleteTicket(i)}><span className='spsans'>Delete</span></button>
                        </div>
                      </div>
                    )
                  }
                  <div className='form_inner'>
                    <div className='form_item'>
                      <label htmlFor={`tickets[${i}]firstName`} className='list_label'>Name<span></span>이름</label>
                      <div className='register_name'>
                        <div className={`${errors.tickets?.[i]?.firstName?.message ? 'is-invalid' : ''}`}>
                          <input
                            type='text'
                            id={`tickets[${i}]firstName`}
                            name={`tickets[${i}]firstName`}
                            {...register(`tickets.${i}.firstName`,{
                              onChange: (e)=>{
                                e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                              }})}
                            placeholder='First name'
                          />
                          <div className='invalid-feedback'>{errors.tickets?.[i]?.firstName?.message}</div>
                        </div>
                        <div className={`${errors.tickets?.[i]?.lastName?.message ? 'is-invalid' : ''}`}>
                          <input
                            type='text'
                            id={`tickets[${i}]lastName`}
                            name={`tickets[${i}]lastName`}
                            {...register(`tickets.${i}.lastName`,{
                              onChange: (e)=>{
                                e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                              }})}
                            placeholder='Last name'
                          />
                          <div className='invalid-feedback'>{errors.tickets?.[i]?.lastName?.message}</div>
                        </div>
                      </div>
                    </div>
                    <div className='form_item'>
                      <label htmlFor={`tickets[${i}]email`} className='list_label'>Email<span></span>이메일</label>
                      <div className='ticket_email'>
                        <div className={`ticket_email_input ${errors.tickets?.[i]?.email?.message ? 'is-invalid' : ''}`}>
                          <input
                            type='email'
                            id={`tickets[${i}]email`}
                            name={`tickets[${i}]email`}
                            {...register(`tickets.${i}.email`,{
                              onChange: (e)=>{
                                e.target.value = e.target.value.replace(/[^0-9a-zA-Z-_.@]/g,'').toLowerCase();
                                setValue(`tickets[${i}].checkEmail`, false)
                              }})}
                            defaultValue={item.email}
                          />
                          <div className='invalid-feedback'>{errors.tickets?.[i]?.email?.message}</div>
                        </div>
                        <div className={`${errors.tickets?.[i]?.checkEmail?.message ? 'is-invalid' : ''}`}>
                          {
                            tickets[i].checkEmail ?
                              (<button className='btn-success' type='button'><span className='spsans'>Success</span></button>) :
                              (
                                <div className='chk-btn'>
                                  <button type='button' className='btn-check' onClick={()=>checkEmail(i)}><span className='spsans'>Check</span></button>
                                  <input type='checkbox' id={`tickets[${i}]checkEmail`} name={`tickets[${i}]checkEmail`}  {...register(`tickets.${i}.checkEmail`)} style={{display: 'none'}}/>
                                </div>
                              )
                          }
                          <div className='invalid-feedback'>{errors.tickets?.[i]?.checkEmail?.message}</div>
                        </div>
                      </div>
                    </div>
                    <div className='ticket_corp'>
                      <div className='form_item'>
                        <label htmlFor={`tickets[${i}]company`} className='list_label'>Company/Division<span></span>회사/사업부</label>
                        <div className='form_item__wrap'>
                          <div className={`${errors.tickets?.[i]?.company?.message ? 'is-invalid' : ''}`}>
                            <select
                              id={`tickets[${i}]company`}
                              name={`tickets[${i}]company`}
                              {...register(`tickets.${i}.company`)}>
                              <option value=''>Please select</option>
                              {
                                Object.keys(companyData).map(key => (
                                  <option value={key}>{key}</option>
                                ))
                              }
                            </select>
                            <div className='invalid-feedback'>{errors.tickets?.[i]?.company?.message}</div>
                          </div>
                          <div className={`ml20 ${errors.tickets?.[i]?.division?.message ? 'is-invalid' : ''}`}>
                            <select id={`tickets[${i}]division`} name={`tickets[${i}]division`} {...register(`tickets.${i}.division`)}>
                              <option value=''>Please select</option>
                              {
                                tickets[i].company && companyData[tickets[i].company].length > 0 && companyData[tickets[i].company].map(name => (
                                  <option value={name}>{name}</option>
                                ))
                              }
                            </select>
                            <div className='invalid-feedback'>{errors.tickets?.[i]?.division?.message}</div>
                          </div>
                        </div>
                      </div>
                      <div className='form_item'>
                        <label htmlFor={`tickets[${i}]region`} className='list_label'>Region/Organization<span></span>지역/법인</label>
                        <div className='form_item__wrap'>
                        
                          <div className={`${errors.tickets?.[i]?.region?.message ? 'is-invalid' : ''}`}>
                            <select
                              id={`tickets[${i}]region`}
                              name={`tickets[${i}]region`}
                              {...register(`tickets.${i}.region`)}>
                              <option value=''>Please select</option>
                              {
                                Object.keys(regionData).map((value, mindex) => (
                                  <option value={value} key={mindex}>{value}</option>
                                ))
                              }
                            </select>
                            <div className='invalid-feedback'>{errors.tickets?.[i]?.region?.message}</div>
                          </div>
                          {/* <div className={`ml20 ${errors.tickets?.[i]?.organization?.message ? 'is-invalid' : ''}`}>
                            <select
                              id={`tickets[${i}]organization`}
                              name={`tickets[${i}]organization`}
                              {...register(`tickets.${i}.organization`, {
                                onChange: (e) => {setValue(`tickets.${i}.countryCode`, regionData[tickets[i]['region']][tickets[i]['organization']])},
                              })}>
                              <option value=''>Please select</option>
                              {
                                tickets[i].region && Object.keys(regionData[tickets[i].region]).map((value, mindex) => (
                                  <option value={value} key={mindex}>{value}</option>
                                ))
                              }
                            </select>
                            <div className='invalid-feedback'>{errors.tickets?.[i]?.organization?.message}</div>
                          </div> */}
                          <div className={`${errors.tickets?.[i]?.organization?.message ? 'is-invalid ml20' : 'ml20'}`}>
                            <input
                              type='text'
                              id={`tickets[${i}]organization`}
                              name={`tickets[${i}]organization`}
                              {...register(`tickets.${i}.organization`)}
                              placeholder='Organization'
                            />
                            <div className='invalid-feedback'>{errors.tickets?.[i]?.organization?.message}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form_item mpMobile-phone mb60'>
                      <label htmlFor={`tickets[${i}]countryCode`} className='list_label'>Mobile Phone<span></span>연락처</label>
                      <div className='form_item__wrap'>
                        <div className={`${errors.tickets?.[i]?.countryCode?.message ? 'is-invalid' : ''}`}>
                          <select
                            id={`tickets[${i}]countryCode`}
                            name={`tickets[${i}]countryCode`}
                            {...register(`tickets.${i}.countryCode`)}>
                            <option value=''>Please select</option>
                            {
                              mobileData.map((value, mindex) => (
                                <option value={value} key={mindex}>{value}</option>
                              ))
                            }
                          </select>
                          <div className='invalid-feedback'>{errors.tickets?.[i]?.countryCode?.message}</div>
                        </div>
                        <div className={`${errors.tickets?.[i]?.mobile?.message ? 'is-invalid' : ''}`}>
                          <input
                            type='text'
                            id={`tickets[${i}]mobile`}
                            name={`tickets[${i}]mobile`}
                            {...register(`tickets.${i}.mobile`,{
                              onChange: (e)=>{
                              e.target.value = e.target.value.replace(/[^0-9]/g,'');
                              // if(e.target.value.length > 0 && e.target.value.substring(0,1) === '0') {
                              //   e.target.value = e.target.value.substring(1,e.target.value.length)
                              // }
                            }})}
                          />
                          <div className='invalid-feedback'>{errors.tickets?.[i]?.mobile?.message}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        <span className='bar mb20'></span>
        <div className='buttons_center mb60'>
          <button type='button' className='btn-plus' onClick={()=>appendTicket()}></button>
        </div>
        <div className='buttons_center'>
          <button type='button' className='btn-cancel mr10' onClick={()=>{setIsSameProfile(false); reset({tickets: [initValue]})}}><span className='spsans'>Cancel</span></button>
          <button type='submit' className='btn-submit'><span className='spsans'>Submit</span></button>
        </div>
      </form>
      </section>
      
      
      <div className='modal fade in' id='modalEmailDbchk' style={{display: isDupEmail ? 'block' : 'none'}}>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content modal-content-p0'>
              <div className='modal-body'>
                <p>{isDupEmailInList ? 'There is a duplicate email in request form.' : 'This email has already registered for the ticket.'}</p>
                <p className='fw-md'>{isDupEmailInList ? '요청 리스트 내 중복된 이메일이 있습니다.' : '티켓 신청이 완료된 사용자입니다.'}</p>
                <p className='fw-md' style={{display: dupEmails ? 'block' : 'none', margin: '5px'}}>{dupEmails}</p>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn-send' onClick={()=>setIsDupEmail(false)}><span className='spsans'>Done</span></button>
              </div>
            </div>
          </div>
      </div>

      {/* submit 경우의 수1 - 이메일 중복체크를 안하고 submit을 클릭할 시 뜨는 모달입니다 */}
      <div className='modal fade in' id='modalEmailDbchk' style={{display: isCheckEmail ? 'block' : 'none'}}>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content modal-content-p0'>
              <div className='modal-body'>
                <strong>Please click the ‘Check’ button</strong>
                <p className='fw-md'>이메일 중복체크를 해주세요.</p>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn-send ssone' onClick={()=>setIsCheckEmail(false)}><span className='spsans'>Done</span></button>
              </div>
            </div>
          </div>
      </div>

      {/* submit 경우의 수2 - 정상적으로 폼을 입력했을 때 예매 전 경고 창입니다. */}
      <div className='modal fade in' id='modalTicketNotice' style={{display: isTicketNotice ? 'block' : 'none'}}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content modal-content-p0'>
            <div className='modal-body'>
              <div className='modal-notice mb40'>
                <div className='modal-notice-tit mb10'>
                  <img src={iconWarning} alt='warning' />
                  <strong>Please check before making a reservation.</strong>
                </div>
                <p className='mb10'>- You can apply by proxy, but if you register someone other than Samsung employees or apply for a false proxy application, please note that we will request and notify your division about the matter.</p>
                <p>If your visit is less than 2 days, Please purchase the "Trade visitor pass" (1-day / 2-days ticket) individually on the IFA ticket site.</p>
              </div>
              <div className='modal-notice'>
                <div className='modal-notice-tit mb10'>
                  <img src={iconWarning} alt='warning' />
                  <strong>예약 전 확인 바랍니다.</strong>
                </div>
                <p className='mb10'>- 대리 신청은 가능하나, 허위 또는 삼성 관계자 외 신청 시 사업부 청구 및 해당사항 통보 예정이니 유의 바랍니다.</p>
                <p>방문 기간이 2일 이하인 경우, IFA 티켓 사이트에서 ‘Trade visitor pass’ (1일권 / 2일권) 개별 구매 바랍니다.</p>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn-cancel mr20' data-dismiss='modal' aria-label='Close'  onClick={()=>setIsTicketNotice(false)}><span className='spsans'>Close</span></button>
              <button type='button' className='btn-submit' onClick={()=>onCreateTicket()}><span className='spsans'>Done</span></button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
