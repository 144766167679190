import { useState } from 'react';
import tourMap from '@assets/images/vipRoute.png';

export default function TourInfomation() {
  const [lang, setLang] = useState('kr');

  return (
    <>
      <div className="container_top ticket_top">
        <div className="top-info top-info_cn">
          <h2 className="top-title">Information</h2>
        </div>
      </div>
      <div className="container_body-tit mb60">
        <h3></h3>
        <div className="container_lang">
          <button className={`btn-en ${lang === 'en' ? 'on':''}`} onClick={() => setLang('en')}><span className="spsans">EN</span></button>
          <button className={`btn-kr ${lang === 'kr' ? 'on':''}`} onClick={() => setLang('kr')}><span className="spsans">KR</span></button>
        </div>
      </div>
            {
        lang === 'en' ? (
          <section className="information_en">
            <div className="information_announce">
              <div className="information_inner">
                <h4>Notice</h4>
              </div>
              <div className="information_inner">
                <p className='listing mb10'><span>-</span> This is VIP tour reservation system for CES 2024 Samsung booth.</p>
                <p className='listing mb10'><span>-</span> VIP tour is only for reserved VIP guests who are hosted by Samsung executives higher than EVP or Head of Regional Office position.</p>
                <p className='listing mb10'><span>-</span> The tour takes about 40 minutes.</p>
                <p className='listing mb10'><span>-</span> Please arrive on time for your tour. If you are more than 10 minutes late we will consider it a no-show and the tour will be cancelled.</p>
                <p className='listing mb10'><span>-</span><div>If your guest is not able to visit on the reserved time and date, please kindly notify the VIP Tour PLC (Soojeong Kim, <a href="mailto:soozeong.kim@samsung.com" className="ft_blue ssone">soozeong.kim@samsung.com</a>)</div></p>
                <p className='listing mb10'><span>-</span> Tours are limited to 15 people to maintain a pleasant tour experience for your guests.</p>
                <p className='listing'><span>-</span> Please be aware that our booth has 2-floor access with stairs. Guests in need of assistance are encouraged to make a request through the reservation page</p>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Meeting Point</h4>
              </div>
              <div className="information_inner">
                <p className='mb40'>The tour will start from VIP Tour desk which is at the corner, on your right when you’re facing main entrance of Samsung exhibition hall.</p>
                <img className="tourMap" src={tourMap}/>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Tour Route</h4>
              </div>
              <div className="information_inner">
                <p className='mb10'>Sustainability - SmartThings - VD - DA - MX</p>
              </div>
            </div>
          </section>
        ) : (
          <section className="information_en">
            <div className="information_announce">
              <div className="information_inner">
                <h4>Notice</h4>
              </div>
              <div className="information_inner">
                <p className='listing mb10'><span>-</span> CES 2024 Samsung VIP 투어 예약 페이지에 오신 것을 환영합니다.</p>
                <p className='listing mb10'><span>-</span> VIP 투어는 부사장급/지역총괄급 이상 삼성 임원이 직접 호스팅 하시는 투어에 한해 신청을 받고 있습니다.</p>
                <p className='listing mb10'><span>-</span> 투어는 총 40분 소요됩니다.</p>
                <p className='listing mb10'><span>-</span> 예약 시간으로부터 10분이 경과될때까지 방문 하지 않으실 경우, 노쇼로 간주되어 투어가 취소됩니다.</p>
                <p className='listing mb10'><span>-</span><div>부득이한 사정으로 최종 확정된 예약 일시에 방문이 어려우신 경우, 반드시 담당자(김수정 프로, <a href="mailto:soozeong.kim@samsung.com" className="ft_blue ssone">soozeong.kim@samsung.com</a>)측에 연락 바랍니다.</div></p>
                <p className='listing mb10'><span>-</span> 게스트 분들의 쾌적한 투어를 위하여 회당 투어 인원은 15인으로 제한하고 있습니다.</p>
                <p className='listing'><span>-</span> 투어 중간에 계단을 이용하는 2층 코스가 포함되어 있습니다. 이 점 양해 부탁드리며, 거동이 불편하시거나 별도의 도움이 필요하신 게스트는 접수 시에 꼭 기입해 주시기 바랍니다.</p>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Meeting Point</h4>
              </div>
              <div className="information_inner">
                <p className='mb40'>투어는 VIP 데스크 앞에서 시작되며, 데스크는 삼성관 메인 입구를 바라보고 섰을 때, 우측 편 코너에 위치하고 있습니다.</p>
                <img className="tourMap" src={tourMap}/>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Tour Route</h4>
              </div>
              <div className="information_inner">
                <p className='mb10'>Sustainability - SmartThings - VD - DA - MX</p>
              </div>
            </div>
          </section>
        )
      }
    </>
  );
}
