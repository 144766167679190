import { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { times } from '@/data/meetingroom';
import roomA from '@/assets/images/BizLoungeA.png';
import roomB from '@/assets/images/BizLoungeB.png';
export default function RoomTimeTableLayout() {
  const [isFloorPlanModal, setIsFloorPlanModal] = useState(false);
  const [floorPlanView, setFloorPlanView] = useState('a');
  return (
    <>
      <div className="container_top ticket_top">
        <div className="top-info top-info_cn">
          <h2 className="top-title">Add Reservation</h2>
        </div>
      </div>
      <section className="room_reserve">
      {/* <div className="room_date-wrap mb40">
        {
          times.map((time, index)=>(
            <div className="room_date" key={time.date}>
              <NavLink to={`/room/reservation/${index}/timetable`}>{time.label}</NavLink>
            </div>
          ))
        }
      </div> */}
      <Outlet />
    </section>
    <div className="modal fade in" id="modalFloorPlan" style={{display: isFloorPlanModal ? 'block' : ''}}>
        <div className="modal-dialog modal-dialog-centered modal-dialog_lg">
          <div className="modal-content">
            <button className="btn-modalclose" type="button" onClick={()=>{setFloorPlanView('a'); setIsFloorPlanModal(false);}}></button>
            <div className="modal-header">
                <h3 className="modal-title">Business Lounge Floor Plan</h3>
            </div>
            <div className="modal-body">
              <div className="floorPlan_top mb40">
                <div className="floorPlan_top-left">
                  <button className={`ssone ${floorPlanView === 'a' && 'on'}`} onClick={()=>setFloorPlanView('a')}>Room 1-8</button>
                  <span className="submenu-bar"></span>
                  <button className={`ssone ${floorPlanView === 'b' && 'on'}`} onClick={()=>setFloorPlanView('b')}>Room 9-15</button>
                </div>
                {/* <div className="floorPlan_top-right">
                  <label htmlFor="onsiteOnly">on-site reservation only</label>
                </div> */}
              </div>
              <div className="floorPlan_img">
              <img src={floorPlanView === 'a' ? roomA : roomB} alt="floor plan" style={{height:'455.77px'}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
