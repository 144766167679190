import { useState } from 'react';
import iconApply from '@assets/images/icon_s5.svg';
import iconSerial from '@assets/images/icon_s6.svg';
import iconTicket from '@assets/images/icon_s7.svg';
import iconPrint from '@assets/images/icon_s8.svg';
import iconReceipt from '@assets/images/icon_s9.svg';

export default function RoomInfomation() {
  const [lang, setLang] = useState('kr');

  return (
    <>
      <div className="container_top ticket_top">
        <div className="top-info top-info_cn">
          <h2 className="top-title">Information</h2>
        </div>
      </div>
      <div className="container_body-tit">
        <h3></h3>
        <div className="container_lang">
        <button className={`btn-en ${lang === 'en' ? 'on':''}`} onClick={() => setLang('en')}><span className="spsans">EN</span></button>
        <button className={`btn-kr ${lang === 'kr' ? 'on':''}`} onClick={() => setLang('kr')}><span className="spsans">KR</span></button>
        </div>
      </div>
      {
        lang === 'en' ? (
          <section className="information_en">
            <div className="information_announce">
              <div className="information_inner">
                <h4>Notice</h4>
              </div>
              <div className="information_inner">
                <p className='mb20'>- Please note that maximum two times of reservation per a day is available.</p>
                <p className='mb20'>- A time of reservation is up to an hour, in 30 minutes unit</p>
                <p className='mb20'>- The reservation is not confirmed immediately.</p>
                <p className=''>- Confirmation email will be sent after administrator’s approval.</p>
              </div>
            </div>
            <div className="information_process">
              <div className="information_inner">
                <h4>Reservation Process</h4>
              </div>
              <div className="information_inner">
                <div className="information_process-box information_process-box4">
                  <div className="process_item process_item4">
                    <div>
                      <span className="process_item-no">01</span>
                      <img src={iconApply} alt="reservation" className="mb30" />
                      <p>Apply Reservation</p>
                    </div>
                  </div>
                  <div className="process_item process_item4">
                    <div>
                      <span className="process_item-no">02</span>
                      <img src={iconReceipt} alt="reservation" className="mb30" />
                      <p>Administrator<br /> Review</p>
                    </div>
                  </div>
                  <div className="process_item process_item4">
                    <div>
                      <span className="process_item-no">03</span>
                      <img src={iconTicket} alt="reservation" className="mb30" />
                      <p>Administrator<br />Approval / Disapproval</p>
                    </div>
                  </div>
                  <div className="process_item process_item4 no_background_item4">
                    <div>
                      <span className="process_item-no">04</span>
                      <img src={iconPrint} alt="reservation" className="mb30" />
                      <p>Notify result<br />by email</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Opening Hour</h4>
              </div>
              <div className="information_inner">
                  <div className="infor_day mb10">Tuesday 9 Jan, 10am - 6pm</div>
                  <div className="infor_day mb10">Wednesday 10 Jan, 9am - 6pm</div>
                  <div className="infor_day mb10">Thursday 11 Jan, 9am - 6pm</div>
                  <div className="infor_day">Friday 12 Jan, 9am - 4pm</div>
                  {/* 
                  <div className="infor_day mb10"><span className="inner_d">- THURSDAY, JAN. 5</span> <span>[ 10 AM-6 PM  ]</span></div>
                  <div className="infor_day mb10"><span className="inner_d">- FRIDAY, JAN. 6</span> <span>[ 9 AM-6 PM  ]</span></div>
                  <div className="infor_day mb10"><span className="inner_d">- SATURDAY, JAN. 7</span> <span>[ Exhibits: 9 AM-5 PM  ]</span></div>
                  <div className="infor_day"><span className="inner_d">- SUNDAY, JAN. 8</span> <span>[ 9 AM-4 PM ]</span></div>
                  */}
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Courtesy</h4>
              </div>
              <div className="information_inner">
                  <p className="mb10">- Avoid occupying the room longer than reserved time</p>
                  <p className="mb10">- Use facilities carefully</p>
                  <p className="mb10">- Keep the room clean</p>
                  <p className="mb10">- If you do not plan to use the room, please cancel your reservation</p>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>For more Info</h4>
              </div>
              <div className="information_inner">
                <p className="mb10">- Jeffrey Fairbanks - <a href="mailto:j.fairbanks@cheil.com" class="ssone">j.fairbanks@cheil.com</a></p>
              </div>
            </div>
          </section>
        ) : (
          <section className="information_en">
            <div className="information_announce">
              <div className="information_inner">
                <h4>Notice</h4>
              </div>
              <div className="information_inner">
                  <p className='mb20'>- 1일 최대 2슬롯 예약이 가능합니다.</p>
                  <p className='mb20'>- 예약은 30분 단위, 1시간까지 예약 가능합니다.</p>
                  <p className=''>- 예약 직후 확정되지 않으며, 담당자 컨펌 후 확정메일이 송부됩니다.</p>
              </div>
            </div>
            <div className="information_process">
              <div className="information_inner">
                <h4>Reservation Process</h4>
              </div>
              <div className="information_inner">
                <div className="information_process-box information_process-box4">
                  <div className="process_item process_item4">
                    <div>
                      <span className="process_item-no">01</span>
                      <img src={iconApply} alt="reservation" className="mb30" />
                      <p>비즈니스 라운지 예약 신청</p>
                    </div>
                  </div>
                  <div className="process_item process_item4">
                    <div>
                      <span className="process_item-no">02</span>
                      <img src={iconReceipt} alt="reservation" className="mb30" />
                      <p>관리자 <br />검토</p>
                    </div>
                  </div>
                  <div className="process_item process_item4">
                    <div>
                      <span className="process_item-no">03</span>
                      <img src={iconTicket} alt="reservation" className="mb30" />
                      <p>관리자<br />승인 / 거절</p>
                    </div>
                  </div>
                  <div className="process_item process_item4 no_background_item4">
                    <div>
                      <span className="process_item-no">04</span>
                      <img src={iconPrint} alt="reservation" className="mb30" />
                      <p>이메일로 결과 안내</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Opening Hour</h4>
              </div>
              <div className="information_inner">
                  <div className="infor_day mb10">- 1월 9일 화요일, 오전 10시 ~ 오후 6시</div>
                  <div className="infor_day mb10">- 1월 10일 수요일, 오전 9시 ~ 오후 6시</div>
                  <div className="infor_day mb10">- 1월 11일 목요일, 오전 9시 ~ 오후 6시</div>
                  <div className="infor_day">- 1월 12일 금요일, 오전 9시 ~ 오후 4시</div>
                  {/* 
                  <div className="infor_day mb10"><span>- 1월 5일 목요일</span> <span className="inner_d2">[ 오전 10시 ~ 오후 6시  ]</span></div>
                  <div className="infor_day mb10"><span>- 1월 6일 금요일</span> <span className="inner_d2">[ 오전 9시 ~ 오후 6시  ]</span></div>
                  <div className="infor_day mb10"><span>- 1월 7일 토요일</span> <span className="inner_d2">[ 전시: 오전 9시 ~ 오후 5시  ]</span></div>
                  <div className="infor_day"><span>- 1월 8일 일요일</span> <span className="inner_d2">[ 오전 9시 ~ 오후 4시 ]</span></div>
                  */}
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Courtesy</h4>
              </div>
              <div className="information_inner">
                  <p className="mb10">- 다음 예약자를 위해, 이용 시간을 준수해 주십시오.</p>
                  <p className="mb10">- 시설 이용 시, 파손 유의 부탁드립니다.</p>
                  <p className="mb10">- 회의실을 깨끗하게 사용해 주십시오.</p>
                  <p className="">- 회의실을 사용하지 않게 된 경우 예약을 취소해 주십시오.</p>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>For more Info</h4>
              </div>
              <div className="information_inner">
                <p className="mb10">- Jeffrey Fairbanks - <a href="mailto:j.fairbanks@cheil.com" class="ssone">j.fairbanks@cheil.com</a></p>
              </div>
            </div>
          </section>
        )
      }
    </>
  );
}
