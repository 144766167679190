const faqEN = [
  {
    categoryCode: 'use',
    category: 'How to use it',
    question: '[Login] Is it impossible to use the site if I don\'t log in?',
    answer: 'This site is based on a member login, and you must log in to use it. If you are not a member, please use the Samsung Dotcom account address after completing your membership.',
  },
  {
    categoryCode: 'use',
    category: 'How to use it',
    question: '[Membership registration] Is it only possible to register through Samsung Dotcom e-mail?',
    answer: `This site is operated for Samsung executives and business partners and can only be applied with Samsung Dotcom and (partner) Samsung Dotcom IDs. When you apply, you will receive an approval email to the email address you entered, so please check the registered email.`,
  },
  {
    categoryCode: 'use',
    category: 'How to use it',
    question: '[Membership registration]Do I have to sign up for each menu of Ticket, Meeting Room and VIP Tour?',
    answer: 'No. Due to the integration of the site, you only need to proceed with the registration once.',
  },
  {
    categoryCode: 'use',
    category: 'How to use it',
    question: '[Membership registration] Do I need to sign up for each event such as CES, MWC, and IFA?',
    answer: 'Yes, we do not keep membership information for each event, and the site is operated separately for each event, so you have to sign up for each event.',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[How to use it] What are the steps to apply for a ticket?',
    answer: `With a time difference, you must go through two application processes to complete the ticket issuance. (1) Sign up for a voucher code at the address below (<a class="ssone" href="${window.location.origin}" tagret="_blank">${window.location.origin}</a>).
(2) The voucher code will be issued on the announced date with the notice mail.<br/>
You must register this code directly on the IFA official website (<a class="ssone" href="https://ifa-berlin-2023.reg.buzz/" tagret="_blank">https://ifa-berlin-2023.reg.buzz/</a>) to obtain a final ticket in PDF format.<br/>
If you have completed the last IFA stage and have not received a PDF ticket, please note that ticket issuance is not possible at the IFA event venue.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[How to use it] What do I need to enter the Berlin IFA event venue?',
    answer: 'Please print the ticket issued on the IFA official website in PDF form or save it on your mobile device and bring it to the venue. At the last step of registering the ticket, (Order Confirmed page) on the IFA official website (<a class="ssone" href="https://ifa-berlin-2023.reg.buzz/" tagret="_blank">https://ifa-berlin-2023.reg.buzz/</a>), click the [Download ticket] or [Mobile ticket] button to save the ticket PDF.',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[How to use it] When will the voucher code be distributed?',
    answer: 'A link will be sent on the announced date, When issuing a ticket, a link (go to site) will be distributed to the mail address registered in [Ticket Request].',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[How to use it] Is there an additional charge when I apply for the ticket?',
    answer: 'No. This event is for executives and employees, so there is no additional charge.',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Subject of Voucher issuance] I\'d like to know the scope of the person who can get the ticket.',
    answer: 'This ticket request applies to employees who are engaged in an exhibition related to IFA 23 Samsung Electronics. Applications for non-exhibition-related work at Samsung Hall or personnel other than executives and employees are prohibited. Also, tickets for separate corporate personnel are not supported.',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Subject of Voucher issuance] I\'m a subject of voucher issuance, but I didn\'t get a voucher code issuance mail. Is there an additional way to apply?',
    answer: 'If you have not received the voucher code approval mail until the 2nd issue date, please contact the person in charge (Eun-seob Kim Pro of Cheil Worldwide).',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[proxy request] Is it possible to apply on behalf of someone else?',
    answer: `Yes, you can apply for someone else at the address below (<a class="ssone" href="${window.location.origin}" tagret="_blank">${window.location.origin}</a>) by adding the applicant information by clicking the [+] button at the bottom of the [Ticket Request] page.<br/>In the case of Samsung event website\s mailing service, it will be registered based on the applicant\s mail address information, not the subject, so please keep this in mind if you register on behalf of the IFA official website later. Also, if you register a person other than the person in charge of the Samsung event or apply for a false proxy application, please note that we will request and notify the business department about the matter.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[proxy request] Is there anything to keep in mind when apply on behalf of someone else?',
    answer: 'When registering a voucher code on the IFA official website, there is a step to authenticate the 6-digit code with the mail of the subject that you filled out on that IFA official website.<br/>For quick application, please maintain real-time contact between the applicant and the subject at the IFA stage.',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Ticket Type] What is the difference between [Exhibiting Client] and [Stand Maintenance badges]?',
    answer: '[Exhibiting Client] allows you to enter during the exhibition period, and [Stand Maintenance badges] allows you to enter during the period of construction and demolition, (excluding exhibition period)',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Ticket Type] Which one should I get issued, [Exhibiting Client] or [Stand Maintenance badges]?',
    answer: 'The [Exhibiting Client] includes the [Stand Maintenance badges] permission.<br/>For those who need access only during the preparation period, please check the Stand Maintenance badges and issue it.',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Ticket Type] Is it possible to enter the venue during periods other than the IFA official exhibition period (September 1st to September 5th)?',
    answer: '[Exhibiting Client] includes [Stand Maintenance badges] permission. During the construction period before and after the exhibition period (August 31st / September 5th at 6 p.m.), admission is possible without restrictions on access time.<br/>However, if you work late at night, please share it with the organizer in advance.',
  },
  // {
  //   categoryCode: 'ticket',
  //   category: 'Ticket',
  //   question: '[Ticket Type] I received both [Exhibitor Ticket] and [Build-up Ticket] voucher codes. If one ticket type is enough for me, is it okay to register only one voucher code on the IFA official website?',
  //   answer: 'Yes, you can register only one of the codes you need and use it.',
  // },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Cancellation of Reservation] business trip has been canceled. How can I cancel it?',
    answer: `If you have only completed the application within this website, no separate cancellation process is required. However, cancellation is not possible on the official registration site, and it will be included in the billing of each business unit, so please proceed carefully after the business trip is confirmed.`
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[Modify reservation contents] The applicant information was entered incorrectly. How can I modify it?',
    answer: `If you enter the wrong information on the Samsung event website, please cancel and re-register only if it is the cancel button with a black background on the address below (<a class="ssone" href="${window.location.origin}/ticket/ticket-list" tagret="_blank">${window.location.origin}/ticket/ticket-list</a>). If the cancel button has already been changed to a gray background and it is impossible to click, please contact the person in charge (Eun-seob Kim of Cheil Worldwide).`
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: '[How to use it] Is there an additional charge when I make a reservation for the conference room?',
    answer: 'No. This event is for executives and employees, so there is no additional charge.',
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: '[How to use it] How many hours a day can I use it when I make a reservation for the conference room?',
    answer: 'The conference room is open for up to an hour. Also, you can apply for up to one case a day. You can make a reservation every 30 minutes, so please note this in mind.',
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: '[How to apply] How can I make a reservation for the Business Lounge?',
    answer: `Reservations are made by Please click on the available time at the address below (<a href="${window.location.origin}/room/reservation/timetable" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/room/reservation/timetable</a>) and complete the required items on the reservation page. Once the reservation is completed, the reservation will be finally approved by the administrator. Once your reservation is finally approved, an approval email will be sent to the email you applied for, so please check your registered email.`,
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: '[Reservation confirmation] I want to know the status of my reservation application.',
    answer: `When the reservation is complete, You can find information about your reservation at <a href="${window.location.origin}/room/application-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/room/application-list</a>) and see how it goes. In addition, if you complete the reservation, you will receive an approved email to the email address you entered with the approval of your administrator.`,
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: '[Modify reservation contents] Information entered incorrectly. How do I fix it?',
    answer: `at the address below (<a href="${window.location.origin}/room/application-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/room/application-list</a>) You can modify the information you entered on the detail page or cancel the reservation. However, the reservation date, time, and Business Lounge cannot be changed after approval, so you can cancel and rebook. Please make a careful reservation.`,
  },
  {
    categoryCode: 'room',
    category: 'Business Lounge',
    question: 'Can I use it just by making a reservation?',
    answer: 'After making a reservation, it can be used with the approval of the manager, and the request may be rejected depending on the reservation situation. If you need to adjust the time, the person in charge will contact you separately. Once your reservation is finally approved, an approval email will be sent to the email you applied for, so please check your registered email.',
  },
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: 'Is there any additional charge or qualification for VIP tour reservation?',
    answer: 'There is no additional charge, but VIP tour is only for reserved VIP guests who are hosted by Samsung executives higher than EVP or Head of Regional Office position.',
  },
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: 'How can I reserve VIP Tour?',
    answer: `Please click the address below (<a href="${window.location.origin}/viptour/reservation" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/viptour/reservation</a>) and complete all required field on the reservation page. Once the reservation is completed, the administrator will give final approval to your reservation. When it is approved, an approval email will be sent to the email you applied for, so please check your registered mail box.`,
  },  
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: 'How many guests can attend the tour?',
    answer: 'Tours are limited to 15 people to maintain a pleasant tour experience for your guests.',
  },  
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: 'Is there language option for VIP Tour?',
    answer: 'Yes. You can choose between English or Korean.',
  },  
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: 'I want to know the status of my reservation.',
    answer: `When you submit your reservation form, you can find information about your reservation at (<a href="${window.location.origin}/viptour/reservation-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/viptour/reservation-list</a>) and see how it goes. Also, you may receive an approval email from administrator when your reservation gets final confirmation.`,
  },  
  {
    categoryCode: 'vip',
    category: 'VIP Tour',
    question: 'I submitted incorrect information. How can I correct it?',
    answer: `If your reservation is in “in process” status, you can edit submitted information or cancel the reservation at <a href="${window.location.origin}/viptour/reservation-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/viptour/reservation-list</a>. If your guest is not able to visit on reserved time, please be sure to notice to VIP Tour PIC(Soojeong Kim : <a href="mailto:soozeong.kim@samsung.com" style="color:#1268bd;text-decoration:underline">soozeong.kim@samsung.com</a>).`,
  }


];

export default faqEN;