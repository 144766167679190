import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import regionData from '@/data/regionData';
import mobileData from '@/data/mobileData';
import companyData from '@/data/companyData';
import {jobs, meetingTypes} from '@/data/conditions';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import _ from 'lodash';
import history from '@/helpers/history';
import { isEmail } from 'validator';

import { me } from '@/actions/auth';
import { updateTicket, ticket, existsEmail } from '@/actions/ticket';
import { setIsLoading } from '@/actions/common';

export default function ReservationEdit() {
  const dispatch = useDispatch();
  const params = useParams();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required field'),
    lastName: Yup.string().required('Required field'),
    company: Yup.string().required('Required field'),
    region: Yup.string().required('Required field'),
    organization: Yup.string().required('Required field'),
    email: Yup.string().email('Email is Invalid').required('Required field'),
    countryCode: Yup.string().required('Required field'),
    mobile: Yup.string().required('Required field'),
    checkEmail: Yup.boolean().required('Please double check your email').oneOf([true], 'Please double check your email'),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  };

  const { register, control, handleSubmit, setValue, setError, reset, formState, watch } = useForm(formOptions);
  const { errors } = formState;

  const [currentItem, setCurrentItem] = useState(null);

  const { basePath } = useSelector((state) => state.common);

  const company = watch('company');
  const region = watch('region');
  const organization = watch('organization');
  const isCheckEmail = watch('checkEmail');
  const email = watch('email');

  console.log('errors', errors)

  // useEffect(() => {
  //   if(organization){
  //     setValue('countryCode', regionData[region][organization])
  //   }
  // }, [organization])

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(ticket(params.ticket_id))
    .then((res) => {
      reset({
        ...res,
        checkEmail: true
      });
      setCurrentItem(res);
      dispatch(setIsLoading(true));
    })
    .catch(message => {
      console.log(message)
    });
  }, []);

  const onSubmit = (data) => {
    dispatch(updateTicket({
      id: params.ticket_id,
      ...data
    }))
    .then((res) => {
      history.navigate(basePath ? `/${basePath}/admin/ticket` : '/admin/ticket', {replace: true})
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });

  }

  const checkEmail = () => {
    if(!isEmail(email) ) {
      setError(`email`, { message: 'please enter email' });
      toast.error('please enter email', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
      return;
    } else {
      setError(`email`, { message: '' });
    }

    dispatch(existsEmail(email))
    .then((res) => {
      if(res.isExistsEmail){
        toast.error('exists email', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
        });
      }else{
        setValue(`checkEmail`, true, { shouldValidate: true })
      }
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  return (
    <div className='container_adm'>
      <div className='container_body-tit'>
        <h3>Modify Ticket Request</h3>
        <span className='tit-desc'>* All fields are required</span>
      </div>
      {
        currentItem && (<section className='ticket-request'>
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
          <div className="form_inner mb100">
            <div className="form_item">
              <label htmlFor="roomhost_name" className="list_label" style={{marginRight: 24}}>Name<span></span>이름</label>
              <div className={`mr20 ${errors.firstName?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id="firstName"
                    name="firstName"
                    maxLength="20"
                    {...register('firstName',{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                      }})}
                    placeholder='First name'
                  />
                  <div className='invalid-feedback'>{errors.firstName?.message}</div>
              </div>
              <div className={`${errors.lastName?.message ? 'is-invalid' : ''}`}>
                <input
                  type='text'
                  id="lastName"
                  name="lastName"
                  maxLength="20"
                  {...register('lastName',{
                    onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                    }})}
                  placeholder='Last name'
                />
                <div className='invalid-feedback'>{errors.lastName?.message}</div>
              </div>
            </div>
            <div className="form_item">
              <label htmlFor="email" className="list_label" style={{marginRight: 24}}>Email<span></span>이메일</label>
              <div className={`mr20 ${errors.email?.message ? 'is-invalid' : ''}`}>
                <input
                  type='email'
                  id={`email`}
                  name={`email`}
                  {...register(`email`,{
                    onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^0-9a-zA-Z-_.@]/g,'').toLowerCase();
                      setValue(`checkEmail`, false)
                    }})}
                  placeholder='Enter your email'
                />
                <div className='invalid-feedback'>{errors.email?.message}</div>
              </div>
              <div className={`${errors.checkEmail?.message ? 'is-invalid' : ''}`}>
                {
                  isCheckEmail ?
                    (<button className='btn-success' type='button'><span className='spsans'>Success</span></button>) :
                    (
                      <div className='chk-btn'>
                        <button type='button' className='btn-check' onClick={()=>checkEmail()}><span className='spsans'>Check</span></button>
                        <input type='checkbox' id={`checkEmail`} name={`checkEmail`}  {...register(`checkEmail`)} style={{display: 'none'}}/>
                      </div>
                    )
                }
                <div className='invalid-feedback'>{errors.checkEmail?.message}</div>
              </div>
            </div>
            <div className='form_item'>
              <label htmlFor={`company`} className='list_label' style={{marginRight: 24}}>Company/Division<span></span>회사/사업부</label>
              <div className={`mr20 ${errors.company?.message ? 'is-invalid' : ''}`}>
                <select
                  id={`company`}
                  name={`company`}
                  className='select_adm br6'
                  {...register(`company`)}>
                  <option value=''>Please select</option>
                  {
                    Object.keys(companyData).map(key => (
                      <option value={key}>{key}</option>
                    ))
                  }
                </select>
                <div className='invalid-feedback'>{errors.company?.message}</div>
              </div>
              {
                company && companyData[company].length > 0 && (
                  <div className={`${errors.division?.message ? 'is-invalid' : ''}`}>
                    <select id={`division`} name={`division`} className='select_adm br6' {...register(`division`)}>
                      <option value=''>Please select</option>
                      {
                        companyData[company].map(name => (
                          <option value={name}>{name}</option>
                        ))
                      }
                    </select>
                    <div className='invalid-feedback'>{errors.division?.message}</div>
                  </div>
                )
              }
            </div>
            <div className="form_item">
              <label htmlFor="region" className="list_label" style={{marginRight: 24}}>Region/Organization<span></span>지역/법인</label>
              <div className={`${errors.region?.message ? 'is-invalid' : ''}`}>
                <select id="region" name="region" {...register('region')} className='select_adm br6'>
                  <option value=''>Please select</option>
                  {
                    Object.keys(regionData).map(key => (
                      <option value={key}>{key}</option>
                    ))
                  }
                </select>
                <div className='invalid-feedback'>{errors.region?.message}</div>
              </div>
              <div className={`${errors.organization?.message ? 'is-invalid ml20' : 'ml20'}`}>
                <input
                  type='text'
                  id="organization"
                  name="organization"
                  maxLength="20"
                  {...register('organization')}
                  placeholder='Organization'
                />
                <div className='invalid-feedback'>{errors.organization?.message}</div>
              </div>
            </div>
            <div className="form_item mpMobile-phone">
              <label htmlFor="countryCode" className="list_label" style={{marginRight: 24}}>Mobile Phone<span></span>연락처</label>
              <div className={`mr20 ${errors.host?.countryCode?.message ? 'is-invalid' : ''}`} style={{width: 220}}>
                <select id="countryCode" name="countryCode" className='select_adm br6' {...register('countryCode')} style={{width: 220}}>
                  <option value=''>Please select</option>
                  {
                    mobileData.map(value => (
                      <option value={value}>{value}</option>
                    ))
                  }
                </select>
                <div className='invalid-feedback'>{errors.countryCode?.message}</div>
              </div>
              <div className={`${errors.mobile?.message ? 'is-invalid' : ''}`} style={{width: 422}}>
                <input
                  type='text'
                  id="mobile"
                  name="mobile"
                  {...register('mobile',{
                    onChange: (e)=>{
                    e.target.value = e.target.value.replace(/[^0-9]/g,'');
                    // if(e.target.value.length > 0 && e.target.value.substring(0,1) === '0') {
                    //   e.target.value = e.target.value.substring(1,e.target.value.length)
                    // }
                  }})}
                  placeholder=''
                />
                <div className='invalid-feedback'>{errors.mobile?.message}</div>
              </div>
            </div>
          </div>
          <span className="bar mb60"></span>
          <div className='buttons_center'>
            <Link to={basePath ? `/${basePath}/admin/ticket` : "/admin/ticket"} replace={true}><button type='button' className='btn-cancel mr10'><span className='spsans'>Cancel</span></button></Link>
            <button type='submit' className='btn-submit'><span className='spsans'>Submit</span></button>
          </div>
        </form>
      </section>)
    }
    </div>
  );
}
