//dev
// const API_SERVER_HOST = 'https://api.dev.ifa-samsung.com';
// const WEBSOCKET_HOST = 'wss://api.dev.ifa-samsung.com'; 

// //live
// const API_SERVER_HOST = 'https://api.ces-demo.din365.com/';
// const WEBSOCKET_HOST = 'wss://api.ces-demo.din365.com'; 

// const API_SERVER_HOST = 'http://3.35.214.33:8082';
// const WEBSOCKET_HOST = 'http://3.35.214.33:8082';

const API_SERVER_HOST = process.env.NODE_ENV === 'production' ? 'https://api.ces-demo.din365.com' : 'http://localhost:8082';
const WEBSOCKET_HOST = process.env.NODE_ENV === 'production' ? 'wss://api.ces-demo.din365.com' : 'ws://localhost:8082'; 

const config = {
  API_SERVER_HOST,
  WEBSOCKET_HOST
}

export default config;