import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import history from '@/helpers/history';
import Moment from 'react-moment';

import { reservation } from '@/actions/tour';
import { setIsLoading } from '../../../actions/common';

export default function ReservationDetail() {
  const dispatch = useDispatch();
  const params = useParams()

  const componentRef = useRef(null);
  const [currentItem, setCurrentItem] = useState(null);

  const { basePath } = useSelector((state) => state.common);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(reservation(params.reservation_id))
    .then((res) => {
      setCurrentItem(res);
      dispatch(setIsLoading(false));
    })
    .catch(message => {
      history.navigate(basePath ? `/${basePath}/admin/tour` : '/admin/tour', {replace: true})
    });
  }, []);

  return (
    <>
    <div className='container_adm'>
      <div className=''>
        <div className='container_body-tit mb10'>
        <h3>VIP Tour Reservation</h3>
          <ReactToPrint
            trigger={() => <span className="tit-desc"><button className="btn-print"></button></span>}
            content={() => componentRef.current}
          />
        </div>
        {currentItem && (
          <section className="vip-reservation adm-rsv-view" ref={componentRef}>
            <form className="form">
            <div className="vip-reservation-tit mb20" style={{position: 'relative'}}>
                  <label htmlFor='tourMemo' className={`tour_memo open`}  data-color={currentItem?.label?.bgColor}>
                    {currentItem?.label?.text ? currentItem?.label?.text : 'Label'}
                  </label>
                </div>
                <div className='form_inner mb60'>
                  <div className="form_item">
                      {currentItem?.memo}
                  </div>
                </div>
              <div className="vip-reservation-tit mb60">
                <h4>Tour Request</h4>
                <span className="tit-desc"></span>
              </div>
              <div className="form_inner mb100">
                <div className="form_item">
                  <label htmlFor="host_dept" className="list_label">Tour Language<span></span>투어 언어</label>
                  <p className="list_info">{currentItem.language}</p>
                </div>
                <div className="form_item">
                  <label className="list_label">Date & Time<span></span>날짜 & 시간</label>
                  <div className="form_info">
                    <p className="list_info"><Moment format='DD.MMM.YYYY'>{currentItem.date}</Moment></p>
                    <p className="list_info">{currentItem.time}</p>
                  </div>
                </div>
              </div>
              <div className="vip-reservation-tit mb60">
                <h4>VIP Tour PIC</h4>
              </div>
              <div className="form_inner mb100">
                <div className="form_item">
                  <label htmlFor="roomhost_name" className="list_label">Name<span></span>이름</label>
                  <p className="list_info">{currentItem.pic.firstName} {currentItem.pic.lastName}</p>
                </div>
                <div className="form_item">
                  <label htmlFor="memb_region" className="list_label">Region / Organization<span></span>지역 / 법인</label>
                  <p className="list_info">{currentItem.pic.region} / {currentItem.pic.organization} </p>
                </div>
                <div className="form_item">
                  <label htmlFor="memb_org" className="list_label">Division<span></span>사업부</label>
                  <p className="list_info">{currentItem.pic.gbm}</p>
                </div>
                <div className="form_item">
                  <label htmlFor="memb_email" className="list_label">Department<span></span>부서</label>
                  <p className="list_info">{currentItem.pic.department}</p>
                </div>
                <div className="form_item">
                  <label htmlFor="memb_email" className="list_label">Email<span></span>이메일</label>
                  <p className="list_info">{currentItem.pic.email}</p>
                </div>
                <div className="form_item">
                  <label htmlFor="memb_ph" className="list_label">Mobile Phone<span></span>연락처</label>
                  <p className="list_info">{currentItem.pic.countryCode} {currentItem.pic.mobile}</p>
                </div>   
              </div>
              <div className="vip-reservation-tit mb60">
                <h4>On-site contact</h4>
              </div>
              <div className="form_inner mb100">
                <div className="form_item">
                  <label htmlFor="atte_memb" className="list_label">Name<span></span>이름</label>
                  <p className="list_info">{currentItem?.site?.firstName} {currentItem?.site?.lastName}</p>
                </div>
                <div className="form_item">
                  <label htmlFor="att_company" className="list_label">Mobile Phone<span></span>연락처</label>
                  <p className="list_info">{currentItem?.site?.countryCode} {currentItem?.site?.mobile}</p>
                </div>
              </div>
              <div className="vip-reservation-tit mb60">
                <h4>Samsung Host</h4>
              </div>
              <div className="form_inner mb100">
                <div className="form_item">
                  <label htmlFor="atte_memb" className="list_label">Department<span></span>소속</label>
                  <p className="list_info">{currentItem.host.department}</p>
                </div>
                <div className="form_item">
                  <label htmlFor="att_company" className="list_label">Executive Name<span></span>성함</label>
                  <p className="list_info">{currentItem.host.executiveName}</p>
                </div>
                <div className="form_item">
                  <label htmlFor="att_company" className="list_label">Executive Position<span></span>직위</label>
                  <p className="list_info">{currentItem.host.executivePosition}</p>
                </div>
              </div>
              <div className="vip-reservation-tit mb60">
                <h4>VIP Guests</h4>
              </div>
              <div className="form_inner">
                <div className="form_item">
                  <label htmlFor="atte_memb" className="list_label">Number of Invitees<span></span>초대받은 인원 수</label>
                  <p className="list_info">{currentItem.inviteNumber}</p>
                </div>
                <div className="form_item mb40">
                  <label htmlFor="atte_memb" className="list_label">Number of Samsung employees<span></span>동행 삼성 임직원 수</label>
                  <p className="list_info">{currentItem.inviteSamsungEmployeesNumber}</p>
                </div>
                <ul className="modal_view clear mb80">
                  {/* {
                    currentItem.guests.map((item, i)=>(
                      <div className="vip_invitee" key={i}>
                        <strong>No.{i+1}</strong>
                        <div>
                          {item.name}
                        </div>
                        <div>
                          {item.company}
                        </div>
                        <div>
                          {item.position}
                        </div>
                      </div>
                    ))
                  } */}
                  <li className="view-four mr20" style={{width: 128}}>
                    <small>No.</small>
                  </li>
                  <li className="view-four mr20">
                    <small>Name</small>
                  </li>
                  <li className="view-four mr20">
                    <small>Company</small>
                  </li>
                  <li className="view-four">
                    <small>Position</small>
                  </li>
                  {
                    currentItem.guests.map((guest,i)=>(
                      <>
                        <li className="view-four mr20" style={{width: 128}}>
                          <span className="modal_value">{i+1}</span>
                        </li>
                        <li className="view-four mr20">
                          <span className="modal_value">{guest.name}</span>
                        </li>
                        <li className="view-four mr20">
                          <span className="modal_value">{guest.company}</span>
                        </li>
                        <li className="view-four">
                          <span className="modal_value">{guest.position}</span>
                        </li>
                      </>
                    ))
                  }
                </ul>
                <div className="form_item mb100" style={{width: '100%',flexDirection:'column'}}>
                  <label htmlFor="currentBusinessIssues" className={`current_issues list_label`} style={{width:'100%',maxWidth:'100%',marginRight:0,marginBottom:24}}>
                    - 당사와의 관계 또는 연관된 비즈니스 현안 <i>(예- 북미 디스플레이 사업 당사 제1거래선)</i><br/>
                    - Business relations to Samsung or relevant business issues <i>(e.g. The 1st partner on display business in North America)</i>
                  </label>
                  <p className="list_info">{currentItem.currentBusinessIssues}</p>
                </div>
              </div>
              {currentItem.mobilityAssistances && currentItem.mobilityAssistances.length > 0 && <>
              <div className="vip-reservation-tit mb50">
                <h4>Additional accommodations</h4>
              </div>
              <div className='vip-reservation-tit'>
              {
              currentItem.mobilityAssistances.map((mobilityAssistance,i)=>(
              <div className="no_mt">
                 <p className='mb20'>No. {i+1}</p>
                  <div className='list_label'>Guest Information<span></span>게스트 정보 입력</div>
                  <p className="list_info mb20">{mobilityAssistance.information}</p>
                  <div className='list_label mt10'>Request for assistance<span></span>요청사항</div>
                  <p className="list_info mb20">{mobilityAssistance.expectedService.join(', ')}</p>
                  {mobilityAssistance.note && <>
                  <div className='list_label mt10'>Additional note or comment<span></span>비고</div>
                  <p className="list_info">{mobilityAssistance.note}</p>
                  </>}
              </div>))
              }
              </div>
              </>}
            </form>   
          </section>
        )}
        <span className="bar mb60"></span>
        <div className="buttons_center mb30">
          <Link to={basePath ? `/${basePath}/admin/tour` : "/admin/tour"} replace={true}><button type="button" className="btn-back"><span className="spsans">List</span></button></Link>
        </div>
      </div>
    </div>
    </>
  );
}
